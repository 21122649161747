import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../services/urlService";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";

function SuperUserDashboard() {
  return (
    <div>
      
      <p></p>
    </div>
  )
}
export default SuperUserDashboard


