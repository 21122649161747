import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import GetUrl from "../../../services/urlService";
import LocalStorageService from '../../../services/localStorageService';
import CurrencyFormat from 'react-currency-format';

const PerformancePage = () => {
  LocalStorageService("remove","servicecategory");
  LocalStorageService("set","servicecategory","party");

const [contactEmail, setContactEmail] = useState('');
const [contactPhoneNumber, setContactPhoneNumber] = useState('');    
const [performance, setPerformance] = useState('');
const [invoices2, setInvoices2] = useState('');
const [results, setResult] = useState([]);
const [item1Name, setItem1Name] = useState([]);
const [item1Price, setItem1Price] = useState([]);
const [item1Qty, setItem1Qty] = useState([]);
const [item2Name, setItem2Name] = useState([]);
const [item2Price, setItem2Price] = useState([]);
const [item2Qty, setItem2Qty] = useState([]);
const [item3Name, setItem3Name] = useState([]);
const [item3Price, setItem3Price] = useState([]);
const [item3Qty, setItem3Qty] = useState([]);
const [item4Name, setItem4Name] = useState([]);
const [item4Price, setItem4Price] = useState([]);
const [item4Qty, setItem4Qty] = useState([]);
const [item5Name, setItem5Name] = useState([]);
const [item5Price, setItem5Price] = useState([]);
const [item5Qty, setItem5Qty] = useState([]);
const [item6Name, setItem6Name] = useState([]);
const [item6Price, setItem6Price] = useState([]);
const [item6Qty, setItem6Qty] = useState([]);
const [hasNoPerf, setHasNoPerf] = useState(false);
const [hasPerf, setHasPerf] = useState(true);
//const [dishNumber, setDishNumber] = useState();

useEffect (() =>{
retrievePerformance(); 
}, [performance]);


function getToken (){
return LocalStorageService("get", "token"); 
}; 

function  getEmail (){
return LocalStorageService("get", "sellerEmail"); 
}; 

function  getPhoneNumber (){
return LocalStorageService("get", "sellerPhoneNumber"); 
}; 

const  getApiPath = () =>  {   
return GetUrl("retrievePerformance");
};

const retrievePerformance = () =>{
const API_PATH = getApiPath(); 
const body = {
eventNumber : window.location.href.split('?')[1],
category: LocalStorageService("get", "servicecategory")
};
//if( getToken()) {
axios({
method: "post",     
url: `${API_PATH}`,
data: body,
})
.then(result => {
  console.log("result data :",result);
  if (result.status === 200) {    
    const perf = result?.data;
    setResult(perf); 
    if(result?.data.length===0)
    {
      setHasNoPerf(true)
    }   
   
    }
  })
  .catch(function (error) {    
    console.log(error);
  });  
     
 // }
};

return (
<div  className="content-akwaba">  
{hasNoPerf &&(<p>This is a one one event</p>)}
      {results.map((item, i) => {
       LocalStorageService("set", "item1nameandprice", item.Item1Name? item.Item1Name +" £" +item.Item1Price:"");
       LocalStorageService("set", "item2nameandprice", item.Item2Name? item.Item2Name +" £" +item.Item2Price:"");
       LocalStorageService("set", "item3nameandprice", item.Item3Name? item.Item3Name +" £" +item.Item3Price:"");
       LocalStorageService("set", "item4nameandprice", item.Item4Name? item.Item4Name +" £" +item.Item4Price:"");
       LocalStorageService("set", "item5nameandprice", item.Item5Name? item.Item5Name +" £" +item.Item5Price:"");
       LocalStorageService("set", "item6nameandprice", item.Item6Name? item.Item6Name +" £" +item.Item6Price:"");
       const item1cos = (item.Item1Price/item.Item1Qty).toFixed(2);
       const item2cos = (item.Item2Price/item.Item2Qty).toFixed(2);
       const item3cos = (item.Item3Price/item.Item3Qty).toFixed(2);
       const item4cos = (item.Item4Price/item.Item4Qty).toFixed(2);
       const item5cos = (item.Item5Price/item.Item5Qty).toFixed(2);
       const item6cos = (item.Item6Price/item.Item6Qty).toFixed(2);
     
        return (
          <div> 
           <Row> 
          <Col> 
           <div className= "item-detailed-description" >
          <div className ="menu-container">
         
          <ul Style="list-style-type: none">
          <li Style="color:blue"><h4><b>Organiser info</b></h4></li>
          <li >Organiser name: {item.OrganiserFullName}</li>         
          <li >Organiser Number: {item.SellerPhone}</li>
          <li >email: {item.email}</li>
         
          <li Style="color:blue"><h4><b>Coordianator info</b></h4></li>     
          <li >title: {item.InserterTitle}</li>
          <li >firstName: {item.InserterName}</li>
           <li >lastName: {item.InserterSurname}</li>
           <li >phoneNumber: {item.phoneNumber}</li> 
           <li >secondary phoneNumber: {item.phoneNumber}</li> 
         
          <li Style="color:blue"><h4><b>Performance info</b></h4></li>         
        <li >Event name: {item.EventName}</li>
        
        <li Style="color:purple"><h5><b>{item.Item1Name? "Performance 1":""}</b></h5></li>
        <li >{item.Item1Name? item.Item1Name :"" }</li>
        <li >{item.Item1Name? "Venue: " +item.Item1Venue :"" }</li>
        <li >{item.Item1Name? "Estimated budget: "+"£"+item.Item1Price:"" }</li>           
        <li >{item.Item1Name?  "Number of places: " + item.Item1Qty:""}</li>
        <li >{item.Item1Name? "Average cost per participant: "+"£"+item1cos:"" }</li>
            
             <li> <p></p>  </li>
        <li Style="color: goldenrod"><h5><b>{item.Item1Name? "Performance 2":""}</b></h5></li>
        <li >{item.Item2Name? item.Item2Name :"" }</li>
        <li >{item.Item2Name? "Venue: " +item.Item2Venue :"" }</li>
        <li >{item.Item2Name? "Estimated budget: "+"£"+item.Item2Price:"" }</li>           
        <li >{item.Item2Name?  "Number of places: " + item.Item2Qty:""}</li>
        <li >{item.Item2Name? "Average cost per participant: "+"£"+item2cos:"" }</li>

        <li> <p></p>  </li>
        <li Style="color: darkgoldenrod"><h5><b>{item.Item3Name? "Performance 3":""}</b></h5></li>
        <li >{item.Item3Name? item.Item2Name :"" }</li>
        <li >{item.Item3Name? "Venue: " +item.Item3Venue :"" }</li>
        <li >{item.Item3Name? "Estimated budget: "+"£"+item.Item3Price:"" }</li>           
        <li >{item.Item3Name?  "Number of places: " + item.Item3Qty:""}</li>
        <li >{item.Item3Name? "Average cost per participant: "+"£"+item3cos:"" }</li>

        <li> <p></p>  </li>
        <li Style="color: lightblue"><h5><b>{item.Item4Name? "Performance 4":""}</b></h5></li>
        <li >{item.Item4Name? item.Item2Name :"" }</li>
        <li >{item.Item4Name? "Venue: " +item.Item4Venue :"" }</li>
        <li >{item.Item4Name? "Estimated budget: "+"£"+item.Item4Price:"" }</li>           
        <li >{item.Item4Name?  "Number of places: " + item.Item4Qty:""}</li>
        <li >{item.Item4Name? "Average cost per participant: "+"£"+item4cos:"" }</li>

        <li> <p></p>  </li>
        <li Style="color: darkgreen"><h5><b>{item.Item5Name? "Performance 5":""}</b></h5></li>
        <li >{item.Item5Name? item.Item5Name :"" }</li>
        <li >{item.Item5Name? "Venue: " + item.Item5Venue :"" }</li>
        <li >{item.Item5Name? "Estimated budget: "+"£"+item.Item5Price:"" }</li>           
        <li >{item.Item5Name?  "Number of places: " + item.Item5Qty:""}</li>
        <li >{item.Item5Name? "Average cost per participant: "+"£"+item5cos:"" }</li>

        <li> <p></p>  </li>
        <li Style="color: orange"><h5><b>{item.Item6Name? "Performance 6":""}</b></h5></li>
        <li >{item.Item6Name? item.Item6Name :"" }</li>
        <li >{item.Item6Name? "Venue: " +item.Item6Venue :"" }</li>
        <li >{item.Item6Name? "Estimated budget: "+"£"+item.Item6Price:"" }</li>           
        <li >{item.Item6Name?  "Number of places: " + item.Item6Qty:""}</li>
        <li >{item.Item6Name? "Average cost per participant: "+"£"+item6cos:"" }</li>
    </ul>
    </div> 
    </div>
           </Col>
         </Row>
    </div>
        )
      })}   
  </div>
 
)
}


export default PerformancePage;