

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../../services/urlService";
import GetEnvironment  from"../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from"../../services/localStorageService";
// import LoadMoreAssets from "./loadPages/LoadMoreAssetsRoot";

function Creative() { 
  const [env,  setEnv] = useState('')
 
  useEffect(() => {
    LocalStorageService("remove", "servicecategory");
  }, [])

  useEffect(() => {
    LocalStorageService("set", "servicecategory", "creative");
  }, [])


 return (
  <div className="container signupforevents-creatives-lp-content"  >
    <div>      
    
        {/* <LoadMoreAssets/>  */}
        <p></p>
    </div>
      </div>
      )
}
export default Creative


