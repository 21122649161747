import {useState, useEffect} from 'react';
import config from '../parties_config.json';
import GetEnvironment from './getEnvironment';


function UploadVideoUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.UploadMkDirVideo; 

        case "live":
        return config.DIRECT_LIVE.UploadMkDirVideo; 

        case "test":
        return config.DIRECT_TEST.UploadMkDirVideo;
        } 
}


function BuyTicketsUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.CreateTicket; 
        case "live":

        return config.DIRECT_LIVE.CreateTicket; 
        case "test":

        return config.DIRECT_TEST.CreateTicket;
        } 
}


function RetrieveTicketByIdUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.RetrieveTicketById; 
        case "live":

        return config.DIRECT_LIVE.RetrieveTicketById; 
        case "test":

        return config.DIRECT_TEST.RetrieveTicketById;
        } 
}


function UploadPicsUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.UploadMkDirPics; 
        case "live":

        return config.DIRECT_LIVE.UploadMkDirPics; 
        case "test":

        return config.DIRECT_TEST.UploadMkDirPics;
        } 
}

function LogoutUrl(){
    switch(GetEnvironment()){
        case "dev":

        return  config.LOCALHOST_DEV.Logout;
        case "live":

        return  config.DIRECT_LIVE.Logout;
        case "test":

        return  config.DIRECT_TEST.Logout;
        }
    
}
 function InsertAssetUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.InsertAsset;
        case "live":

        return config.DIRECT_LIVE.InsertAsset;
        case "test":

        return config.DIRECT_TEST.InsertAsset;
        }
}

 function RetrieveAssetUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.RetrieveAsset;
        case "live":

        return config.DIRECT_LIVE.RetrieveAsset;
        case "test":

        return config.DIRECT_TEST.RetrieveAsset;
        }
}
function RetrieveAssetPartiesUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.RetrieveAssetParties;
        case "live":

        return config.DIRECT_LIVE.RetrieveAssetParties;
        case "test":

        return config.DIRECT_TEST.RetrieveAssetParties;
        }
}


 function DisplayAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
  
        return config.LOCALHOST_DEV.DisplayAsset;    
        case "live":
   
        return config.DIRECT_LIVE.DisplayAsset;    
        case "test":

        return config.DIRECT_TEST.DisplayAsset; 
        }
}

 function GetContactUsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.ContactUs;
        case "live":
        return  config.DIRECT_LIVE.ContactUs;
        case "test":
        return  config.DIRECT_TEST.ContactUs;
        }
}

function GetBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.Bookings;
        case "live":
        return  config.DIRECT_LIVE.Bookings;
        case "test":
        return  config.DIRECT_TEST.Bookings;
        }
    }

 function RetrieveMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RetrieveMyMessages;
        case "live":
        return config.DIRECT_LIVE.RetrieveMyMessages;
        case "test":
        return config.DIRECT_TEST.RetrieveMyMessages;
        }

}
 function GetAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.Appointments;
        case "live":
        return config.DIRECT_LIVE.Appointments;
        case "test":
        return config.DIRECT_TEST.Appointments;
        }
    
}
 function RetrieveBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RetrieveBookings;
        case "live":
        return config.DIRECT_LIVE.RetrieveBookings;
        case "test":
        return config.DIRECT_TEST.RetrieveBookings;
        }

}


function RetrieveInterestsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RetrieveInterests;
        case "live":
        return config.DIRECT_LIVE.RetrieveInterests;
        case "test":
        return config.DIRECT_TEST.RetrieveInterests;
        }

}


function RetrieveAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RetrieveAppointments;
        case "live":
        return config.DIRECT_LIVE.RetrieveAppointments;
        case "test":
        return config.DIRECT_TEST.RetrieveAppointments;
        }
}

 function RegisterUserUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RegisterUser;
        case "live": 
        return config.DIRECT_LIVE.RegisterUser; 
        case "test":  
        return config.DIRECT_TEST.RegisterUser; 
        }      

}

function ResetPasswordUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.ResetPassword;
        case "live": 
        return config.DIRECT_LIVE.ResetPassword; 
        case "test":  
        return config.DIRECT_TEST.ResetPassword; 
        }      

}


 function LoginUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.Login;
        case "live": 
        return config.DIRECT_LIVE.Login;
        case "test":
        return config.DIRECT_TEST.Login;
    }

}
 function LoginManagerUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.LoginManager;
        case "live":
        return config.DIRECT_LIVE.LoginManager;
        case "test":
        return config.DIRECT_TEST.LoginManager;
        }
    
}



function SearchUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.Search; 
        case "live":
        return config.DIRECT_LIVE.Search; 
        case "test":
        return config.DIRECT_TEST.Search;
        } 
}

function GenerateInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GenerateInvoice; 
        case "live":
        return config.DIRECT_LIVE.GenerateInvoice; 
        case "test":
        return config.DIRECT_TEST.GenerateInvoice;
        }        
}

function GetInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetInvoice; 
        case "live":
        return config.DIRECT_LIVE.GetInvoice; 
        case "test":
        return config.DIRECT_TEST.GetInvoice;
        }        
}

function GetInvoiceByNumberUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetInvoiceByNumber; 
        case "live":
        return config.DIRECT_LIVE.GetInvoiceByNumber; 
        case "test":
        return config.DIRECT_TEST.GetInvoiceByNumber;
        }        
}



function GetAssetDetailByIdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetAssetDetailsById; 
        case "live":
        return config.DIRECT_LIVE.GetAssetDetailsById; 
        case "test":
        return config.DIRECT_TEST.GetAssetDetailsById;
        }        
}



function GetPaymentCardUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.MakePayment; 
        case "live":
        return config.DIRECT_LIVE.MakePayment; 
        case "test":
        return config.DIRECT_TEST.MakePayment;
        }        
}

function SendPaymentConfirmationdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.SENDPaymentConfirmation; 
        case "live":
        return config.DIRECT_LIVE.SENDPaymentConfirmation; 
        case "test":
        return config.DIRECT_TEST.SENDPaymentConfirmation;
        }        
}



function GenerateOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GenerateOrder; 
        case "live":
        return config.DIRECT_LIVE.GenerateOrder; 
        case "test":
        return config.DIRECT_TEST.GenerateOrder;
        }        
}

function GetOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetOrder; 
        case "live":
        return config.DIRECT_LIVE.GetOrder; 
        case "test":
        return config.DIRECT_TEST.GetOrder;
        }        
}



function GeneratePerformanceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GeneratePerformance; 
        case "live":
        return config.DIRECT_LIVE.GeneratePerformance; 
        case "test":
        return config.DIRECT_TEST.GeneratePerformance;
        }        
}


function GetPerformanceAsOrganiserUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetPerformanceAsOrganiser; 
        case "live":
        return config.DIRECT_LIVE.GetPerformanceAsOrganiser; 
        case "test":
        return config.DIRECT_TEST.GetPerformanceAsOrganiser;
        }        
}

function GetPerformanceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.GetPerformance; 
        case "live":
        return config.DIRECT_LIVE.GetPerformance; 
        case "test":
        return config.DIRECT_TEST.GetPerformance;
        }        
}


function RetrieveBuyerMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.RetrieveBUYERMessages;
        case "live":
        return config.DIRECT_LIVE.RetrieveBUYERMessages;
        case "test":
        return config.DIRECT_TEST.RetrieveBUYERMessages;
        }
}


 
export default function GetUrl(tarGetUrl){
    switch(tarGetUrl){
  
        case "buyTicket":
            return BuyTicketsUrl(); 

        case "retrieveTicketById":
            return RetrieveTicketByIdUrl(); 

            

        case "bookings":
            return GetBookingsUrl(); 

        case "contactUs":
            return GetContactUsUrl();

        case "displayAsset":
            return DisplayAssetUrl();

           
        case "generatePerformance":
             return GeneratePerformanceUrl();

        case "retrievePerformance":
            return GetPerformanceUrl();

        case "retrievePerformanceAsOrganiser":
            return GetPerformanceAsOrganiserUrl();

        case "generateInvoice":
            return GenerateInvoiceUrl();

        case "generateOrder":
            return GenerateOrderUrl();

        case "getAssetDetailsById":
            return GetAssetDetailByIdUrl(); 

        case "getPaymentCard":
                return GetPaymentCardUrl(); 
       
        case "insertAsset":
            return InsertAssetUrl();
    
         case "login":
            return LoginUrl(); 
    
        case "loginManager":
            return LoginManagerUrl();
    
        case "logout":
            return LogoutUrl(); 

        

        case "retrieveInvoices":
            return GetInvoiceUrl(); 

        case "retrieveInvoiceByNumber":
            return GetInvoiceByNumberUrl();       
    
        case "retrieveOrder":
                return GetOrderUrl();      

        case "registerUser":
            return RegisterUserUrl();


        case "retrieveAppointments":
            return RetrieveAppointmentsUrl(); 
           
        case "retrieveAsset":
            return RetrieveAssetUrl();

         case "retrieveAssetParties":
              return RetrieveAssetPartiesUrl();

        case "retrieveBookings":
            return RetrieveBookingsUrl(); 
    
        case "retrieveBuyerMessages":
            return RetrieveBuyerMessagesUrl();       

        case "retrieveInterests":
            return RetrieveInterestsUrl();

        case "retrieveMessages":
            return RetrieveMessagesUrl(); 

        case "resetPassword":
            return ResetPasswordUrl();

        case "search":
            return SearchUrl();
    
        case "sendPaymentConfirmation":
                
            return SendPaymentConfirmationdUrl();      
       
        case "uploadPics":
            return UploadPicsUrl(); 

        case "uploadVideo":
            return UploadVideoUrl(); 
                
        }    
}



