import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
// import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";

//import ServiceSubscription from "../siteAdmin/customerfeatures/ServiceSubscription";
import ServiceSubscription from "./dashboard/ServiceSubscriptionParty";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";

import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import BuyTicket from "../../categories/booking/BuyATicket";
import CollectAnInviteAsVIP from "../../categories/booking/CollectAVIPInvite";
import CollectInvite from "../../categories/booking/CollectInvite";
import ConfirmParticipationToEvent from "../../categories/booking/CollectInvite";
// import ApproximateCostPerParticipant from "../../payment/ApproximateCostPerParticipant";
// // import ApproximateCostOfEvent from "../../payment/ApproximateCostPerEvent";

import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";

import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";

import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplaymyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";


// import MakePayment from "../../payments/MakePaymentRoot";

import Help from "./help";
import Visits from "./UserVisits";

import CreatePerformance from "../performancesandorders/performances/CreatePerformances";
import Performances from "../performancesandorders/performances/PerformancePage";

class PartyBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpparty" component={Help} />      
          <Route path="/visitsparty" component={Visits} />              
          <Route path="/loginscreenparty" component={Loginscreen} />   
          <Route path="/contactparty" component={Contact} />     
          <Route path="/registeruserparty" component={RegisterUser} />
          <Route path="/performancesparty" component={Performances} /> 
          <Route path="/generateperformanceparty" component={CreatePerformance} /> 
          <Route path="/changepasswordparty" component={ChangePassword} /> 
          <Route path="/loginparty" component={Login} /> 
          <Route path="/logoutparty" component={Logout} /> 
          <Route path="/servicesubparty" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedparty" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsparty" component={UploadAssets} />
          <Route path="/uploadpicturesparty" component={UploadPictures} />
          <Route path="/uploadvideosparty" component={UploadVideo} />          
          <Route path="/detailsdevparties" component={AssetDetailsPageDev} />
          <Route path="/detailsparties" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationparty" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedparty" component={ThankyouPasswordChanged} />
          <Route path="/buyticketparty" component={BuyTicket} />
          <Route path="/collectaninviteasvipparty" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinviteparty" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventparty" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinviteparty" component={CreateOrdinaryInvite} />
          <Route path="/createvipinviteparty" component={CreateVIPInvite} />
          <Route path="/createeventticketparty" component={CreateEventTicket} />
          <Route path="/displayinviteparty" component={DisplayInvite} />
          <Route path="/displayperformanceparty" component={DisplayPerformance} />
          <Route path="/displayeventticketparty" component={DisplayEventTicket} />
          <Route path="/manageeventparty" component={ManageEvent} />
          <Route path="/displaymydashboardparty" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsparty" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(PartyBody);
