

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../../services/urlService";
import GetEnvironment  from"../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
// import LoadMoreAssets from "./loadPages/LoadMoreAssetsRoot";
function Festivals() { 
  const [env,  setEnv] = useState('')
 
  useEffect(() => {
    LocalStorageService("remove", "servicecategory");
  }, [])

  useEffect(() => {
    LocalStorageService("set", "servicecategory", "festival");
  }, [])


  return (  
    <div className="container signupforevents-festival-lp-content">      
    
       {/* <LoadMoreAssets/>  */}
       <p></p>   
       
        </div>
        )
}
export default Festivals


