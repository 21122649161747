import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionCustomEvents";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import CreatePerformance from "../performancesandorders/performances/CreatePerformances";
import PerformancePage from "../performancesandorders/performances/PerformancePage";


class AllcategoriesBody extends Component {
  render() {
    return (
        <HashRouter> 
         {/* <Route path="/makepaymentbtcuevt" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbpcuevt" component={MakePaymentBp} /> 
          <Route path="/makepaymentolcuevt" component={MakePaymentOl} />  */}

          <Route path="/helpcuevt" component={Help} />      
          <Route path="/visitscuevt" component={Visits} />              
          <Route path="/loginscreencuevt" component={Loginscreen} />   
          <Route path="/contactcuevt" component={Contact} />     
          <Route path="/registerusercuevt" component={RegisterUser} />
          <Route path="/performancescuevt" component={PerformancePage} /> 
          <Route path="/generateperformancecuevt" component={CreatePerformance} /> 
          <Route path="/changepasswordcuevt" component={ChangePassword} /> 
          <Route path="/logincuevt" component={Login} /> 
          <Route path="/logoutcuevt" component={Logout} /> 
          <Route path="/servicesubcuevt" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedcuevt" component={ThankYouUploaded} /> 
          <Route path="/uploadassetscuevt" component={UploadAssets} />
          <Route path="/uploadpicturescuevt" component={UploadPictures} />
          <Route path="/uploadvideoscuevt" component={UploadVideo} />          
          <Route path="/detailsdevcustomevents" component={AssetDetailsPageDev} />
          <Route path="/detailscustomevents" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationcuevt" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedcuevt" component={ThankyouPasswordChanged} />
          <Route path="/buyticketcuevt" component={BuyTicket} />
          <Route path="/collectaninviteasvipcuevt" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitecuevt" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventcuevt" component={ConfirmParticipationToEvent} />
          {/* <Route path="/approximatecostperparticipantcuevt" component={ApproximateCostPerParticipant} />  */}
          {/* <Route path="/approximatecostofeventcuevt" component={ApproximateCostOfEvent} />            */}
          <Route path="/createordinaryinvitecuevt" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitecuevt" component={CreateVIPInvite} />
          <Route path="/createeventticketcuevt" component={CreateEventTicket} />
          <Route path="/displayinvitecuevt" component={DisplayInvite} />
          <Route path="/displayperformancecuevt" component={DisplayPerformance} />
          <Route path="/displayeventticketcuevt" component={DisplayEventTicket} />
          <Route path="/manageeventcuevt" component={ManageEvent} />
          <Route path="/displaymydashboardcuevt" component={DisplayMyDashboard} />         
          {/* <Route path="/makepaymentcuevt" component={MakePayment} /> */}
          <Route path="/seeparticipantscuevt" component={SeeParticipants} />
          {/* <Route path="/uploadpictureshairstylecuevt" component={Help} /> 
          <Route path="/generateinvoicehairstylecuevt" component={GenerateInvoice} /> 
          <Route path="/invoicepagehairstylecuevt" component={InvoicePage} />
          <Route path="/invoicebynumberpagehairstylecuevt" component={InvoiceByNumberPage} />          
          <Route path="/generatemenuehairstylecuevt" component={GenerateHairStyleMenue} /> 
          <Route path="/hairStylemenuepagecuevt" component={ HairStyleMenuePage} />
          <Route path="/menueasastylistpagehairstylecuevt" component={HairStyleMenueASAStylistPage} />
          <Route path="/generateorderhairstylecuevt" component={GenerateOrder} /> 
          <Route path="/orderspagehairstylecuevt" component={OrdersPage} /> 
          <Route path="/makepaymenthairstylecuevt" component={MakePayment} />
          <Route path="/sendpaymentconfirmationhairstylecuevt" component={SendPaymentConfirmation} /> 
           */}
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(AllcategoriesBody);
