import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionSport";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import CreatePerformances from "../performancesandorders/performances/CreatePerformances";
import PerformancePage from "../performancesandorders/performances/PerformancePage";

class SportBody
 extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpsport" component={Help} />      
          <Route path="/visitssport" component={Visits} />              
          <Route path="/loginscreensport" component={Loginscreen} />   
          <Route path="/contactsport" component={Contact} />     
          <Route path="/registerusersport" component={RegisterUser} />
          <Route path="/performancessport" component={PerformancePage} /> 
          <Route path="/generateperformancesport" component={CreatePerformances} /> 
          <Route path="/changepasswordsport" component={ChangePassword} /> 
          <Route path="/loginsport" component={Login} /> 
          <Route path="/logoutsport" component={Logout} /> 
          <Route path="/servicesubsport" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedsport" component={ThankYouUploaded} /> 
          <Route path="/uploadassetssport" component={UploadAssets} />
          <Route path="/uploadpicturessport" component={UploadPictures} />
          <Route path="/uploadvideossport" component={UploadVideo} />          
          <Route path="/detailsdevsport" component={AssetDetailsPageDev} />
          <Route path="/detailssport" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationsport" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedsport" component={ThankyouPasswordChanged} />
          <Route path="/buyticketsport" component={BuyTicket} />
          <Route path="/collectaninviteasvipsport" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitesport" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventsport" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinvitesport" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitesport" component={CreateVIPInvite} />
          <Route path="/createeventticketsport" component={CreateEventTicket} />
          <Route path="/displayinvitesport" component={DisplayInvite} />
          <Route path="/displayperformancesport" component={DisplayPerformance} />
          <Route path="/displayeventticketsport" component={DisplayEventTicket} />
          <Route path="/manageeventsport" component={ManageEvent} />
          <Route path="/displaymydashboardsport" component={DisplayMyDashboard} />
          <Route path="/seeparticipantssport" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(SportBody
);
