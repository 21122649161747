import { React, useState } from 'react';
import LoadContactUsMessages from './LoadContactUsMessages'
import LoadInterestsMessages from './LoadInterestsMessages'
import LoadBookings from './LoadBookings'
import LocalStorageService from '../services/localStorageService';

function GetMessageDashboard(){

    function loginToken (){
        return LocalStorageService("get", "token")
      }; 

    const token = loginToken();
    const isLoggedin = token!=null;

    const [toggleState, setToggleState] = useState(1);

    const  toggleTab =(index) => {
                setToggleState(index);
            }

    return (
       <div className ="container">
         {isLoggedin && (<div>
        <div className ="bloc-tabs">
        <div className={toggleState===1 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(1)}>
         Contact Messages
        </div>
        <div className={toggleState===2 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(2)}>
        Interests
        </div>
        <div className={toggleState===3 ? "tabs active-tabs": "tabs"} onClick={()=>toggleTab(3)}>
        Bookings
        </div>        
       </div>

        <div className ="content-tabs">
            <div className={toggleState===1 ? "content active-content":"content"}>
                <h2>Contact Messages </h2>                 
                 <LoadContactUsMessages/>
            </div>

            <div className={toggleState===2 ? "content active-content":"content"}>
                <h2>Expressed Interests </h2>
               < LoadInterestsMessages/>
            </div>

            <div className={toggleState===3 ? "content active-content":"content"}>
                <h2>Bookings</h2>               
                <LoadBookings/>
            </div>
        </div>
        </div>
         )} 
        </div>   
       
    )
}

export default GetMessageDashboard