import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import GetUrl from "../../services/urlService";
import LocalStorageService from '../../services/localStorageService';
import { NavLink, Link, HashRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
//import SetAppName from '../partiesServices/setAppName';
import Card from "react-bootstrap/Card";
import provisionalticket from "../../images/provisonalticket.png";
import finalticket from "../../images/finalticketparties.png";
import MakePayment from "../../payments/MakePaymentRoot";

const FreeTicketPage = () => {  
  const [contactEmail, setContactEmail] = useState('');
  const [ticket, setTicket] = useState('');
  //const [invoices2, setInvoices2] = useState('');
  const [results, setResult] = useState([]);
  const [paidStatus, setPaidStatus] = useState([]);  
  const [item1Name, setItem1Name] = useState([]);
  const [item1Price, setItem1Price] = useState([]);
  const [item1Qty, setItem1Qty] = useState([]);
  const [item2Name, setItem2Name] = useState([]);
  const [item2Price, setItem2Price] = useState([]);
  const [item2Qty, setItem2Qty] = useState([]);
  const [item3Name, setItem3Name] = useState([]);
  const [item3Price, setItem3Price] = useState([]);
  const [item3Qty, setItem3Qty] = useState([]);
  const [item4Name, setItem4Name] = useState([]);
  const [item4Price, setItem4Price] = useState([]);
  const [item4Qty, setItem4Qty] = useState([]);
  const [item5Name, setItem5Name] = useState([]);
  const [item5Price, setItem5Price] = useState([]);
  const [item5Qty, setItem5Qty] = useState([]);
  const [item6Name, setItem6Name] = useState([]);
  const [item6Price, setItem6Price] = useState([]);
  const [item6Qty, setItem6Qty] = useState([]);
  const [Id, setId] = useState('');
  const [eventName, setEventName] = useState('');

  useEffect(()=>{
    setEventName(window.location.href.split('#')[1].replace('?','').replace('/',''))
  })


  var lastIndex = window.location.href.lastIndexOf('?');
   var invoiceno   = window.location.href.substring(lastIndex+ 1);

  const getData =  () => {   
    const API_PATH = getApiPath();   
    const body = {
      invoiceNumber:invoiceno//window.location.href.split('?')[1]
    };    
    axios({
      method: "post",     
      url: `${API_PATH}`,
      data: body,
    }).then(result => {
      if (result.status === 200) { 
        const ticket = result?.data;     
        setResult(ticket);
        setPaidStatus(result?.data[0].Status)    
        }
      })
      .catch(function (error) {    
        console.log(error);
      }); 
  };

  useEffect(() => {
    getData();
    }, [])


useEffect (() =>{
  setContactEmail(LocalStorageService("get", "userEmail"))
}, );

const  getApiPath = () =>  {   
  return GetUrl("retrieveTicketById");
};

return (
  <div  className="content-akwaba">
        {results.map((item) => {   
          return ( 
            // <div className="ticketStyle">
            // <div><p></p>{item.ValidTo}</div> 
            //        <Row >  
            //  <Col>
            //  <div>
            //  <ul Style="list-style-type: none">
            //  <li >Ticket Number: {item.RandomNumber}</li>
            //  <li >Valid from: {item.ValidFrom}</li> 
            //  <li >Valid to: {item.ValidTo}</li> 
            // </ul>
            // </div>
            //  <p></p>
            //  </Col>
            //  <Col md={{ span: 6, offset: 2 }}>  
            //  <ul Style="list-style-type: none"> 
            // <div>        
            //  <li >Title: {item.Title}</li>
            //  <li >First Name: {item.FirstName}</li>
            // <li >Last Name: {item.LastName}</li>
            //   </div>
            //   </ul>
            //   </Col>
            // </Row>
            // </div>

            <div className="ticketStyle">
            <p></p>
            <Row >  
            <Col>
            <div>
            <ul Style="list-style-type: none">
            <li >Ticket Number: {item.RandomNumber}</li>
            <li >Valid from: {item.ValidFrom}</li> 
            <li >Valid to: {item.ValidTo}</li> 
           </ul>
           </div>
            <p></p>
            </Col>
            <Col md={{ span: 6, offset: 2 }}>  
            <ul Style="list-style-type: none"> 
           <div>        
            <li >Title: {item.Title}</li>
            <li >First Name: {item.FirstName}</li>
             <li >Last Name: {item.LastName}</li>
             </div>
             </ul>
             </Col>
           </Row>
           <Row>
           <Col>
           <ul Style="list-style-type: none">           
          <p></p>
       
          <li >Event code: {item.eventName}</li> 
          <li >{item.Performance1!=" "? "Performance 1: "+item.Performance1: ""}</li>
          <li >{item.Performance1!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance2!=" "? "Performance 2: "+item.Performance2: ""}</li>
          <li >{item.Performance2!=" "? "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance3!=" "?  "Performance 3: "+item.Performance3: ""}</li>
          <li >{item.Performance3!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance4!=" "?  "Performance 4: "+item.Performance4: ""}</li>
          <li >{item.Performance4!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance5!=" "? "Performance 5: "+item.Performance5: ""}</li>
          <li >{item.Performance5!=" "? "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance6!=" "? "Performance 6: "+item.Performance6: ""}</li>
          <li >{item.Performance6!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>         
           <p></p>
           <p Style ="color: red">Powered by Marveltech sponsored by Sign up for events</p>
           <p></p>
           <p></p>
           </ul>
           </Col>

           </Row>          
      </div>
          )
        })}
         
    </div>   
    )
  }

 
export default FreeTicketPage;