import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Creative from  "./categories/creative/Creative";
import Spectacles from  "./categories/spectacles/Spectacles";
import Ceremonies from  "./categories/ceremonies/Ceremonies";
import Customevents from "./categories/customevents/Customevents";
import Leisure from "./categories/leisure/Leisure";
import Festivals from "./categories/festivals/Festivals";
import Tourism from "./categories/tourism/Tourism";
import Trainings from "./categories/trainings/Trainings";
import Sport from "./categories/sport/Sport";
import Parties from "./categories/parties/Parties";
import Fairs from "./categories/fairs/Fairs";
import Music from "./categories/music/Music";
import Entertainments from "./categories/entertainments/Entertainments";
import Conferences from "./categories/conferences/Conferences";
import PartyTicketPage from "./categories/booking/TicketPage";
import HomeDashboard from"./HomeDashboard";
import PartyBody from "./categories/parties/PartyBody";
import EntertainmentBody from "./categories/entertainments/EntertainmentBody";
import SportBody from "./categories/sport/SportBody";
import AllcategoriesBody from "./categories/allcategories/AllcategoriesBody";
import CustomEventsBody from "./categories/customevents/CustomEventBody";
import LeisureBody from "./categories/leisure/LeisureBody";
import TrainingBody from "./categories/trainings/TrainingBody";

import Allcategory from "./categories/allcategories/Allcategory";
import Disclaimer from "./footer/disclaimer";
import Help from "./footer/help";
import Follow from "./footer/followus";
import Join from "./footer/joinus";
import Ask from "./footer/askus";
import CollectATicket from "./categories/collections/CollectATicket";
import MakePaymentBankTransfert  from "./payments/MakePaymentBankTransfert";
import MakePaymentBp  from "./payments/MakePaymentbp";
import MakePaymentOl  from "./payments/MakePaymentol";
import CreateEventBase from "./categories/createanevent/CreateEventBase";
import ThankyouAfterUploaded from "./categories/createanevent/ThankyouAfterUploaded";
import UploadPictures from "./categories/createanevent/UploadPictures";
import UploadVideo from "./categories/createanevent/UploadVideo";
import Register from "./registerlogin/RegisterUser"
import MakePaymentRoot  from "./payments/MakePaymentRoot";
import AdminBody from "./admin/AdminDashBoardBody";
import FreeTicketPage from "./categories/booking/FreeTicketPage";
import ContributionTicketPage from "./categories/booking/ContributionTicketPage";
import PaidTicketPage from "./categories/booking/PaidTicketPage";

class Main extends Component {
  render() {
    return (
      <div>   
        <AdminBody/>
        <PartyBody/>
        <EntertainmentBody/>
        <SportBody/>
        <AllcategoriesBody/>
        <CustomEventsBody/>
        <LeisureBody/>
        <TrainingBody/> 
        <HashRouter>
        <Route exact path="/" component={HomeDashboard} />
          <Route exact path="/home" component={HomeDashboard} />      
          <Route path="/homedashboard" component={HomeDashboard} />   
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/help" component={Help} />          
          <Route path="/followus" component={Follow} />
          <Route path="/joinus" component={Join} />         
          <Route path="/contact" component={Ask} />
          <Route path="/askus" component={Ask} />
          <Route path="/registersgeneral" component={Register} />
          <Route path="/collectaticketpage" component={CollectATicket} /> 
          <Route path="/partyticketpage" component={PartyTicketPage} /> 
          <Route path="/makepaymentbt" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbp" component={MakePaymentBp} /> 
          <Route path="/makepaymentol" component={MakePaymentOl} /> 
          <Route path="/makepaymentroot" component={MakePaymentRoot} /> 
          <Route exact path="/creativities" component={Creative} />
          <Route exact path="/spectacles" component={Spectacles} />
          <Route exact path="/conferences" component={Conferences} />
          <Route exact path="/entertainment" component={Entertainments} />
          <Route path="/music" component={Music} />  
          <Route path="/parties" component={Parties} />
          <Route path="/sport" component={Sport} />          
          <Route path="/fairs" component={Fairs} />
          <Route path="/leisure" component={Leisure} />
          <Route path="/trainings" component={Trainings} />
          <Route path="/tourism" component={Tourism} />
          <Route path="/festivals" component={Festivals} /> 
          <Route path="/customevents" component={Customevents} />
          <Route path="/ceremonies" component={Ceremonies} /> 
          <Route path="/allcategories" component={Allcategory} /> 
          <Route path="/uploadassets" component={CreateEventBase} /> 
          <Route path="/thankyouuploaded" component={ThankyouAfterUploaded} />
          <Route path="/uploadpictures" component={UploadPictures} /> 
          <Route path="/uploadvideos" component={UploadVideo} />  
          <Route path="/freeticketpage" component={FreeTicketPage} /> 
          <Route path="/paidticketpage" component={PaidTicketPage} /> 
          <Route path="/contributionticketpage" component={ContributionTicketPage} /> 
                          
         <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
