import React, { useState, useEffect } from "react";
import axios from "axios";
import {NavLink, HashRouter } from "react-router-dom";
import GetUrl from "../../services/urlService";
import { slice } from 'lodash';
import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import LocalStorageService from '../../services/localStorageService';
import { useTranslation  } from "react-i18next";
import GetEnvironment  from "../../services/getEnvironment";

const CreateComment = (props) => {

  const { t } = useTranslation();   
  useEffect(() => {
    setEnv(LocalStorageService("get", "environment")??GetEnvironment())
  }, []) ;
 
  useEffect(() => {
    LocalStorageService("clear");
    }, []);
          
// const prodid = window.location.href.split('?')[1];

  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');
  const [messageSent, setMessageSent] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [titleerrormsg, setTitleerrormsg] = useState('');
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [reasonerrormsg, setReasonerrormsg] = useState('');
  const [propertyId, setPropertyId] = useState(localStorage.getItem('propId'));  
  const [type, setType] = useState('Comment');
  const [Id, setId] = useState(); 
  const [env,  setEnv] = useState('');
  const[sellerPhoneNumber, setSellerPhoneNumner]= useState();
  const[address, setAddress ]= useState();
  const[sellerName, setSellerName ]= useState();
  const[startDate, setStartDate ]= useState();
  const[endDate, setEndDate ]= useState();
  const[startTime, setStartTime]= useState();
  const[endTime, setEndTime]= useState();

  const getApiPathForCreateComment = () => { 
    return GetUrl("createCommentByTopic")
  };

  const handleSubmit = event => {
    console.log('handleSubmit ran');
    //setPropertyId(window.location.href.split('?')[1])
    const propertyId = window.location.href.split('?')[1];
    event.preventDefault(); // 👈️ prevent page refresh
   const body ={fname,lname,title,email,phonenumber,comment,propertyId,type }; 
   
   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     if (validate()) {            
      const API_PATH = getApiPathForCreateComment();
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) {         
            setFirstName('');
            setLastName('');
            setTitle('');
            setEmail('');
            setPhoneNumber('');
            setComment('');
            setEmailerrormsg('');
            setFirstNameerrormsg('');
            setLastNameerrormsg('');
            setTitleerrormsg('');
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');
            setReasonerrormsg('');
            setMessageSent(true);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    } else { 
      setClickedButtonButNotPosted(true);
      setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
      console.log("Data was not sent");
      return;
    }
  }

 const  validate = ()=> {
    let isValid = true; 
    if (!title) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    
    if (!fname) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.contact.text.firstnameinvalidmsg")}</p>);
    }

    if (!lname) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.contact.text.lastnameinvalidmsg")}</p>);
    }

    if (!phonenumber) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }
    if (!email) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }
      
    return isValid;
  };
 
  
  return (     
        
    <div className="container">   
    <Row>      
  <div className="Container-detail-page content-akwaba">    
      <div className="col-md-9 offset-2">
      <form action="#">      
        <div className="form-group">
        <label>Query type </label>
        <input
         readonly 
          id="type"
          name="type"
          type="text"
          value={type}
          onChange={event => setType(event.target.value)}          
          placeholder="Comment"        
        />          
        </div>
        <div className="form-group">
        <label>{t("pages.contact.text.title")} </label>
             <select name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>                
              </select>
          <div className="text-danger">{titleerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.firstname")} </label>
        <input
          id="first_name"
          name="first_name"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)}          
          placeholder={t("pages.contact.text.firstnameph")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
         <label>{t("pages.contact.text.lastname")}</label>
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)}
          placeholder={t("pages.contact.text.lastnameph")}
        />
          <div className="text-danger">{lnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.email")}</label>
        <input
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder={t("pages.contact.text.emailph")}
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>

      <div className="form-group">
              <label>{t("pages.contact.text.phone")}</label>
         <input
          id="phonenumber"
          name="phonenumber"
          type="text"
          onChange={event => setPhoneNumber(event.target.value)}
          placeholder={t("pages.contact.text.phoneph")}
          value={phonenumber}
        />
          <div className="text-danger">{phonenumbererrormsg}</div>
        </div>

        <div className="form-group">
        <label>Your comment</label>
        <textarea
          id="comment"
          name="comment"
          type="text"
          rows={6}
          value={comment}          
          onChange={event => setComment(event.target.value)}
          placeholder="Add your comment."
        />
          <div className="text-danger">{reasonerrormsg}</div>      
       </div>
     
       <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {handleSubmit(e)}}
              defaultValue={t("pages.contact.text.submit")}
            />
       
        <div>
               {messageSent && (
                <div>
                Thank you your comment is submitted<br />
              
                </div>
              )}
              
              {clickedButtonButNotPosted && (
                <div>
                  {clickedNotPostedMessage} <br />                  
                </div>
                 )}
         </div>
         </form>
         </div>
  </div>
    </Row>
    <p></p>     
    </div>    
  );
};

 export default CreateComment