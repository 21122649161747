import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, Link, HashRouter, useHistory} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LocalStorageService from './services/localStorageService';
import DashboardCarousel from './DashboardCarousel';
import FindMoreImage from './imagesfromvuejs/findoutmore.png';
import axios from "axios";
import GetUrl from "./services/urlService";
import Button from 'react-bootstrap/Button';
import config from './config.json';
import HomeDashboardMix from './mixLandingPages/HomeDashboardMix';

 function HomeDashboard() {
  const history = useHistory();
    //const { t } = this.props;
    LocalStorageService("remove","servicecategory")
    LocalStorageService("set","servicecategory", "home")
    const [post, setPost] = useState([])
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(3) 
    const [search,  setSearch] = useState(false)
    const [isSearchValid,  setIsSearchValid] = useState(true)
    const [siteSearch,  setSiteSearch] = useState('')
    const [siteRoot,  setSiteRoot] = useState('')
    const [env,  setEnv] = useState('')
    const [urlpath,  setUrlpath] = useState('')
    const [siteSearchIndex,  setSiteSearchIndex] = useState('')
    const [eventType,  setEventType] = useState('')
    const [eventId,  setEventId] = useState('')
    
    function getApiPathForSearch () {     
      return GetUrl("searchDashboard");   
    }

    function getApiPathForSearchByName () {     
      return GetUrl("searchDashboardByName");   
    }
 
    useEffect(() => {
      environmentShortDescription();
    }, [])
  
    const  environmentShortDescription = () =>{ 
      if(config.ENVIRONMENT.DEV){
        setEnv("dev")
        }
        if(config.ENVIRONMENT.LIVE){
          setEnv("")
        }

        if(config.ENVIRONMENT.TEST){
          setEnv("test")
        }
    }

    const  handleSearchByCode = (e) =>{
      const url = getApiPathForSearch();

      if(siteSearch=="")
      {     
        setIsSearchValid(false);
        return
      }
      axios.post(url, {         
         siteSearch: siteSearch           
            })
            .then(res => { 
              if (res.status === 200) 
              {
                history.push("details"+`${env}`+res.data[0].TypeOfEvent+'?'+res.data[0].Id);
              }
       
             setSearch(true); 
             setSiteSearch('');
         })
         .catch((e) => console.log(e))    
    } 

    const  handleSearchByName = (e) =>{
      const url = getApiPathForSearchByName();

      if(siteSearch=="")
      {     
        setIsSearchValid(false);
        return
      }
      axios.post(url, {         
         siteSearch: siteSearch           
            })
            .then(res => { 
              if (res.status === 200) 
              {
                history.push("details"+`${env}`+res.data[0].TypeOfEvent+'?'+res.data[0].Id);
              }
       
             setSearch(true); 
             setSiteSearch('');
         })
         .catch((e) => console.log(e))   
    } 
    
  
    return (
      <div>
        <div>  
      <DashboardCarousel/>
       </div>     
     <HashRouter> 
    
      <br/> <br/> 
  <div className="justify-content-md-center "> 
   <div>
   <label for="site-search"  Style ="color:goldenrod" ><h2>Start searching </h2></label>
  </div>
   <div >
   <Col>  
   <input type="search"   value={siteSearch} id="siteSearch"
          onChange={event => setSiteSearch(event.target.value)}
          Style ="border-radius:5px; border: solid goldenrod; width: auto">
   </input>
 <div></div>
     <Button variant="info" Style ="width:80px; paddingRight: 10px; margin: 10px" onClick={(e) =>  {handleSearchByCode (e)}} type ='submit'> Code</Button>
 
     <Button variant="info" Style ="width:100px" onClick={(e) =>  {handleSearchByName(e)}} type ='submit'>Name</Button>
   
   </Col>
   </div>
  </div>

      <br/> <br/>  
<div>
<div className="container">
  <div className="row justify-content-md-center">
  <div className="col col-lg-3 m13">            
        <NavLink to="/allcategories"><h4><p className="a">Creativity</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
        <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m14">            
        <NavLink to="/entertainment"><h4><p className="a">Spectacles</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
        <div className="placeholder-dashboard"></div>
    </div>
    
    </div>
  </div>
  <div className="row justify-content-md-center">
    <div className="col col-lg-3 m3">
        <NavLink to="/entertainment"><h4><p className="a">Music</p></h4>
          <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink>        
        <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m4">
        <NavLink to="/parties"><h4><p className="a">Parties</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink>        
         <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m5">  
      <NavLink to="/sport"><h4><p className="a">Sport</p></h4> 
      <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
      </NavLink>   
     <div className="placeholder-dashboard"></div>
    </div>
  </div>
 <div className="row justify-content-md-center">
    <div className="col col-lg-3 m6">
      <NavLink to="/allcategories"><h4><p className="a">Fairs</p></h4> 
      <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
      </NavLink> 
       <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m7">        
       <NavLink to="/trainings"><h4><p className="a">Trainings</p></h4>
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
        </NavLink> 
      <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m8">            
        <NavLink to="/leisure"><h4><p className="a">Holidays</p></h4>
         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
         </NavLink> 
         <div className="placeholder-dashboard"></div>
    </div>
  </div>
 <div className="row justify-content-md-center">
    <div className="col col-lg-3 m9">            
        <NavLink to="/parties"><h4><p className="a">Festivals</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink> 
         <div className="placeholder-dashboard"></div>
    </div>
     <div className="col col-lg-3 m10"> 
        <NavLink to="/leisure"><h4><p className="a">Leisure</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
         <div className="placeholder-dashboard"></div>     
    </div>
    <div className="col col-lg-3 m11"> 
        <NavLink to="/customevents"><h4><p className="a">Custom events</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
         <div className="placeholder-dashboard"></div>     
    </div>
    </div>
    <div className="row justify-content-md-center">
     <div className="col col-lg-3 m12">            
        <NavLink to="/allcategories"><h4><p className="a">Ceremonies</p></h4> 
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
        <div className="placeholder-dashboard"></div>
    </div>
    
    <div className="col col-lg-3 m1">
          <NavLink to="/trainings"><h4>
            <div className="a">         
              Conferences
           </div>
           </h4>
             <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
           </NavLink>
          <div className="placeholder-dashboard"></div>
    </div>

    <div className="col col-lg-3 m2"> 
        <NavLink to="/entertainment"><h4><p className="a">Entertainments</p></h4>
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink>     
    <div className="placeholder-dashboard"></div>
    </div> 
  </div>
</div>
<br/> <br/>  
 <HomeDashboardMix/>
  <br/><br/>
 </HashRouter> 
</div>

    )
     
    }

export default withTranslation() (HomeDashboard);


// import React, {Component,  useState, useEffect } from 'react';
// import { withTranslation } from "react-i18next";
// import { NavLink, Link, HashRouter } from "react-router-dom";
// import { Row, Col } from 'react-bootstrap';
// import LocalStorageService from './services/localStorageService';
// import DashboardCarousel from './DashboardCarousel';
//  import FindMoreImage from './imagesfromvuejs/findoutmore.png';

// class HomeDashboard extends Component {
//   render() {
//     const { t } = this.props;
//     LocalStorageService("remove","servicecategory")
//     LocalStorageService("set","servicecategory", "home")
//     return (
//       <div>

//         <div>  
//       <DashboardCarousel/>
//        </div>     
//      <HashRouter> 
// <div>
//  <div className="justify-content-md-center">
//  <input type="text"  placeholder="Search dashboard"  /> <br/> <br/> 
// </div>

// <div className="container">
//   <div className="row justify-content-md-center">
//   <div className="col col-lg-3 m13">            
//         <NavLink to="/allcategories"><h4><p className="a">Creativity</p></h4> 
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//          </NavLink> 
//         <div className="placeholder-dashboard"></div>
//     </div>
//     <div className="col col-lg-3 m14">            
//         <NavLink to="/entertainment"><h4><p className="a">Spectacles</p></h4> 
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//          </NavLink> 
//         <div className="placeholder-dashboard"></div>
//     </div>
    
//     </div>
//   </div>
//   <div className="row justify-content-md-center">
//     <div className="col col-lg-3 m3">
//         <NavLink to="/entertainment"><h4><p className="a">Music</p></h4>
//           <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//         </NavLink>        
//         <div className="placeholder-dashboard"></div>
//     </div>
//     <div className="col col-lg-3 m4">
//         <NavLink to="/parties"><h4><p className="a">Parties</p></h4> 
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//         </NavLink>        
//          <div className="placeholder-dashboard"></div>
//     </div>
//     <div className="col col-lg-3 m5">  
//       <NavLink to="/sport"><h4><p className="a">Sport</p></h4> 
//       <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//       </NavLink>   
//      <div className="placeholder-dashboard"></div>
//     </div>
//   </div>
//  <div className="row justify-content-md-center">
//     <div className="col col-lg-3 m6">
//       <NavLink to="/allcategories"><h4><p className="a">Fairs</p></h4> 
//       <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//       </NavLink> 
//        <div className="placeholder-dashboard"></div>
//     </div>
//     <div className="col col-lg-3 m7">        
//        <NavLink to="/trainings"><h4><p className="a">Trainings</p></h4>
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//         </NavLink> 
//       <div className="placeholder-dashboard"></div>
//     </div>
//     <div className="col col-lg-3 m8">            
//         <NavLink to="/leisure"><h4><p className="a">Holidays</p></h4>
//          <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//          </NavLink> 
//          <div className="placeholder-dashboard"></div>
//     </div>
//   </div>
//  <div className="row justify-content-md-center">
//     <div className="col col-lg-3 m9">            
//         <NavLink to="/parties"><h4><p className="a">Festivals</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//         </NavLink> 
//          <div className="placeholder-dashboard"></div>
//     </div>
//      <div className="col col-lg-3 m10"> 
//         <NavLink to="/leisure"><h4><p className="a">Leisure</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//          </NavLink> 
//          <div className="placeholder-dashboard"></div>     
//     </div>
//     <div className="col col-lg-3 m11"> 
//         <NavLink to="/customevents"><h4><p className="a">Custom events</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//          </NavLink> 
//          <div className="placeholder-dashboard"></div>     
//     </div>
//     </div>
//     <div className="row justify-content-md-center">
//      <div className="col col-lg-3 m12">            
//         <NavLink to="/allcategories"><h4><p className="a">Ceremonies</p></h4> 
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
//          </NavLink> 
//         <div className="placeholder-dashboard"></div>
//     </div>
    
//     <div className="col col-lg-3 m1">
//           <NavLink to="/trainings"><h4>
//             <div className="a">         
//               Conferences
//            </div>
//            </h4>
//              <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//            </NavLink>
//           <div className="placeholder-dashboard"></div>
//     </div>

//     <div className="col col-lg-3 m2"> 
//         <NavLink to="/entertainment"><h4><p className="a">Entertainments</p></h4>
//         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
//         </NavLink>     
//     <div className="placeholder-dashboard"></div>
//     </div> 



//   </div>
// </div>
//  </HashRouter> 
// </div>

//     )
     
//     }
//   }  
 


// export default withTranslation() (HomeDashboard);

