
import { useTranslation } from 'react-i18next';
import { React,useState, useEffect, useRef} from "react";
import axios from "axios";
import Select from 'react-select';
import GetUrl from '../services/urlService';
import LocalStorageService from '../services/localStorageService';
import {Row, Col} from 'react-bootstrap';
import useAbortController from 'react-use-cancel-token';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import Config from "../config.json";
import Paypalscript from "../paypal/Paypalscript"

const MakePaymentRoot = () => {

  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [mobilenumber, setMobileNumber] = useState('');
  const [sortcode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [results, setResult] = useState([]);
  const { newAbortSignal, cancelPreviousRequest, isCancel } =  useAbortController();
 
 
 function getPaymentByPhoneNumber(){
    return "Text payment with your provisional ticket number " + LocalStorageService("get","TicketNumber") + " and your full name to " +  Config.PAYMENTS.Phone1 + " or " + Config.PAYMENTS.Phone2
 }
  function loginToken (){
    return LocalStorageService("get", "token")
  }; 

   useEffect (() =>{
    setContactEmail(LocalStorageService("get", "userEmail"));
    fetchAccountDetails()
  }, [])

   function getApiPath () { 
    return GetUrl("getPaymentCard")
  };

   
//  const email = loginEmail();
 const token = loginToken();

    const  fetchAccountDetails = () => {
// if(!hasLoadedBefore){

        const API_PATH = getApiPath();
          axios({
            method: "get",
            url: `${API_PATH}`            
          })
            .then((result) => {
              if (result.status === 200) {         
                setResult(result.data);       
              }
            })
            .catch(function (error) {    
              console.log(error);
            }); 
        }
 
return(
    <>
    <span><h2>Choose your payment method </h2></span>
    {results.map((item) => { 
          return (
      <Accordion defaultActiveKey="0"> 
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Account to account
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
    <Card.Body className="content-accordion" >
        <div className="container">
          <div  className="content-akwaba">
    
            <div>
            <Row >           
            <Col md={{ span: 6, offset: 2 }}>  
            <div className ="invoices-container">
          <ul Style="list-style-type: none">         
            <li Style="color:Black"><h3><b>Account details: </b></h3></li>
            <li key={item.id}>Account Number: {results[0].Account}</li>
            <li key={item.id}>Sort: {results[0]?.Sort}</li> 
            <li key={item.id}>Bank: {results[0]?.Bank}</li>
            <li key={item.id}>Holder: {results[0]?.FullName}</li> 
            <p></p>
           </ul>
           </div>
            </Col>         
           </Row>
         </div>
       
        </div>
        </div>
    
        </Card.Body>
    </Accordion.Collapse>
    </Card>


    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    By phone 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
    <Card.Body className="content-accordion" >
        <div className="container">
        {/* {getPaymentByPhoneNumber()} */}
        <ul Style="list-style-type: none">         
            <li Style="color:Black"><h3><b>Pay by phone details </b></h3></li>
            <li key={item.id}>Primary Mobile Number: {results[0].Phone1}</li>
            <li key={item.id}>Second Mobile Number: {results[0]?.Phone2}</li> 
            <li key={item.id}>Sendwave Mobile Number: {results[0]?.Phone2}</li>             
            <p></p>
           </ul>
        </div>
    </Card.Body>
    </Accordion.Collapse>
    </Card>

    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
    Online payment     
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
    <Card.Body className="content-accordion"> 
    <div className="container">    
    <Paypalscript/>    
    </div>        
        </Card.Body>
    </Accordion.Collapse>
    </Card>

    </Accordion>   
       )
    })}
    
    </>

)
      }
export default (MakePaymentRoot);