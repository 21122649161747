

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../../services/urlService";
import GetEnvironment  from "../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
// import LoadMoreAssetsRoot from './loadPages/LoadMoreAssetsRoot';
// import NavBarConferences from "./responsive/Navbar";
function Conferences() { 
  const [env,  setEnv] = useState('')  
  return (  
    <div className="container signupforevents-party-lp-content"> 
    
       {/* <NavBarConferences/>
       <LoadMoreAssetsRoot/>  */}
       <p></p>   
       
        </div>
        )
}
export default Conferences

