import React, { Component } from "react";
 
class Followus extends Component {
  render() {
    return (
      <div className ="container" >

<p> Follow Us for the Latest Updates</p>

<p> <b>Connect with Eventnest on Social Media</b></p>

Stay in the loop with the latest news, events, and behind-the-scenes glimpses by following us on social media. Join our growing community of food enthusiasts, event planners, and satisfied customers!

<p> <b>Facebook</b></p>

[Link to your Facebook Page]

👍 Like us on Facebook for:

- Exclusive promotions and discounts
- Event highlights and photo albums
- Recipe tips and kitchen insights

<p> <b>Instagram</b></p>

[Link to your Instagram Profile]

📸 Follow us on Instagram for:

- Mouthwatering food photography
- Real-time updates on events
- Insta-stories featuring our team and creations

<p> <b>Twitter</b></p>

[Link to your Twitter Profile]

🐦 Follow us on Twitter for:

- Quick updates and announcements
- Engaging conversations with our community
- Retweets from happy customers

<p><b>Pinterest</b> </p>

[Link to your Pinterest Profile]

📌 Pin with us on Pinterest for:

- Inspiring event and wedding ideas
- Creative recipe collections
- Boards showcasing our diverse catering offerings

<p> <b>LinkedIn</b></p>

[Link to your LinkedIn Page]

🤝 Connect with us on LinkedIn for:

- Professional insights into the catering industry
- Job opportunities and company news
- Networking with our team and industry professionals

<p> <b>YouTube</b></p>

[Link to your YouTube Channel]

🎥 Subscribe to our YouTube Channel for:

- Culinary tutorials and cooking demos
- Event highlights and customer testimonials
- Behind-the-scenes glimpses of our kitchen

<p> <b>How to Stay Updated</b> </p>

1. Follow: Click the "Follow" or "Like" button on our social media profiles to receive updates directly in your feed.
<br/>
2. Turn on Notifications:** Enable notifications for our profiles to be the first to know about new posts, promotions, and live events.
<br/>
3. Engage: Like, share, comment, and tag us in your posts. We love hearing from our community!
<br/>
Thank you for joining us on our social media journey. We look forward to connecting with you online and sharing the excitement of Manblay. If you have any questions or suggestions, feel free to reach out to us through social media or our contact page.
<br/>
<p>[Contact Information]</p>

<br/>
 </div>
    );
  }
}
 
export default Followus;