import { useTranslation } from 'react-i18next';
import { React,useState, useEffect} from "react";
import axios from "axios";
// import GetApis from '../pages/GetApis';
import Select from 'react-select';
import CONFIG from '../../../leisure_config.json';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import {NavLink, HashRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import setAppName from "../../../leisureServices/setAppName";

const ThankyouAfterUploaded = () => {
     const [time, setTime] = useState(Date.now());
     setAppName();
     useEffect(() => {
          const interval = setInterval(() => setTime(Date.now()), 300);
          return () => {
          clearInterval(interval);
          };
          }, []);

     
        const { t } = useTranslation();    
        return (             
        <div className='justify-content'>
    <Row className="margin-maker">    
    <Col md={{ span: 9, offset: 2}}>
    <div>
     <span style ={{color: 'goldenrod'}}>Thank you. Your media are uploaded</span> 
    </div>
    <NavLink to="/home">
    <input
         className="btn btn-primary"
         type="submit" 
         value="return to home page"
            />
     </NavLink>   
    </Col>
    </Row>
    </div>
         )
    }  


export default ThankyouAfterUploaded
