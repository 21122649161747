// //import PartyTicketPage from "../../categories/parties/booking/TicketPage"

import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import GetEnvironment  from "../../services/getEnvironment";
import GetUrl from "../../services/urlService";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
import TicketPage from "../../categories/booking/TicketPage";
import Button from 'react-bootstrap/Button';
import GlobalConfig from "../../config.json";
import { NavLink, Link, HashRouter,useHistory} from "react-router-dom";

  const CollectATicket = () => {

  const [env,  setEnv] = useState('')
  const [category,  setCategory] = useState('')
  const [ticketid,  setTicketid] = useState('')
  const [errormsg,  setErrormsg] = useState('')
  const [isvalid,  setIsValid] = useState(false)
  const [ticketType,  setTicketType] = useState()
  const [invoiceNumber,  setInvoiceNumber] = useState('')
  const [canGoToTicketPage,  setCanGoToTicketPage] = useState(false)
  
 const history = useHistory();

  useEffect(() => {
  setCategory(LocalStorageService("get", "servicecategory"))
  }, [])
 
   function getApiPath () { 
    return GetUrl("getTicketType")
  };

  function validate(event){  
  if (typeof ticketid !== "undefined" && ticketid.length >10) {
    setIsValid(true);  
  }
  else{
    setErrormsg(<p>Invalid ticket number</p>);
  }
  return isvalid;
}

const  retrieveTicketType = (event) =>{
 event.preventDefault(); // 👈️ prevent page refresh
 const body ={
 invoiceNumber
};   
   validate();   
    const API_PATH = getApiPath();
    axios({
      method: "post",     
      url: `${API_PATH}`,
      data: body,
    })
      .then((result) => {
        if (result.status === 200) {          
          setTicketType(result.data[0].TicketType);        
          setTicketid('');
          console.log(result);       
          setCanGoToTicketPage(true);
        }
      })
      .catch(function (error) {    
        console.log(error);
      });  
   // }    
}

const goToTicketPage = () =>{
   switch(ticketType){
    case "D": history.push( '/contributionticketpage?'+`?${invoiceNumber}`)
    case "F": history.push( '/freeticketpage?'+`?${invoiceNumber}`)
    case "P": history.push( '/paidticketpage?'+`?${invoiceNumber}`)
  }
}

return (
  <div> 
   <form action ="#">
  <div className="container transaction-verification-container">
  <Col>
   <label><h3>Verify your ticket number</h3></label>
   </Col>
   <Col>
   <p></p>
   <input        
        id="ticketid"
        name="ticketid"
        type="text"
        value={invoiceNumber}
        onChange={event => setInvoiceNumber(event.target.value)} 
        placeholder="Insert your invoice number"
      />
   </Col>  
   {!canGoToTicketPage &&(<Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
        <div><Button variant="info"  type="submit" onClick={(event) => {retrieveTicketType(event)}} className ="invoice-buttons">Search ticket </Button></div>
    </Col> )}  

   {canGoToTicketPage &&(<Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
         <p>Ticket found. Click button to retrieve</p>
         <p></p>
          <div><Button variant="info"  type="submit" onClick={() => {goToTicketPage()}} className ="invoice-buttons">Get ticket</Button></div>
          </Col>)}
   </div>       
    </form>
  <div></div>
   </div>
    )
}
export default (CollectATicket) 
