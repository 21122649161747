
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
// import CONFIG from "../../parties_config.json";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
// import GetUrl from "../../partiesServices/urlServiceParty";
// import GetEnvironment  from "../../partiesServices/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GetUrl from "../services/urlService";
import Button from 'react-bootstrap/Button';

function Paypalscript() {
 
  const [env,  setEnv] = useState('')
const [show, setShow]= useState(false)
const [success, setSuccess]= useState(false)
const [errorMessage, setErrorMessage]= useState(false)
const [orderId, setOrderId]= useState(false)
const [results, setResults]= useState(false)
const [paiementValue, setPaiementValue]= useState(false)


function  getId (){  
  return LocalStorageService("get", "TicketNumber"); 
}; 

const  getApiPath = () =>  {   
  return GetUrl("retrievePaymentValueByTicketId");
};

const getData = async () => {
  const API_PATH = getApiPath(); 
  
  const body = {
    Id: getId()
  };

  const result = await 
  axios({
    method: "post",     
    url: `${API_PATH}`,
    data: body,
  })  
  setPaiementValue(result?.data[0].TotalPrice)

  console.log('result:', results);
};

useEffect(() => {
  getData();
  }, [])

  useEffect(() => {
  
  }, [])

  const createOrder = (data, actions) => {
return actions.order.create({
  purchase_units:[
    {
      description:"Ticket price for ticket number "+ getId (),
      amount:{
        currency_code:"USD",
        value: paiementValue
          
      }
    }
  ],
  application_context:{
    shipping_preference:"NO_SHIPPING. EMAILS OR SMS"
  }
})
.then((orderID)=>{
  setOrderId(orderID)
  return orderID
})
  }
  const onApprove = (data, actions) =>{
    
  }

  const  onError  = (data, actions) =>{
    
  }
 
 return (
    <div>      
       <PayPalScriptProvider
           //buyer modeste kple
//email: sb-3dvbq28975943@business.example.com
//pw:D$S0fA>!
      //  options ={{
      //   "client-id":"AapxsXeP7PpcGITP6EWYAFj7OoBKjdtc3A924TD74Pf5gLX1AXeSFKd_q-5NFjaIKbljBnse91n34nUa"
      //  }}

      //seller marveltech
      //pw: 3LqSYk#N
      //email:sb-wqkxr29146447@business.example.com
      options ={{
          "client-id":"AX0Dg-ZPrVnce2JkH42o4hZCdA1X38WCKpp1pag44Fksjja-Q_Aq_OD12sZ09A5Zerwad6sx4xkrXaYk"
         }}
      
       >
      <Col md={{ span: 4, offset:3 }}>
     {!show &&  <Button variant="info" Style ="width:100px; paddingRight: 10px; margin: 10px" onClick ={()=>setShow(true)} type ='submit'> buy now</Button>}
     </Col>
       <p></p>
      {show? ( <PayPalButtons style={{layout:'vertical'}} createOrder = {createOrder}
       onApprove = {onApprove} onError = {onError}
       />):null}

       {success ? (
        <h1>Your Payment has been done successfully please check email</h1>
       ): null}
       </PayPalScriptProvider>
      </div>
      )
}
export default Paypalscript
