import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionAllcategories";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import CreatePerformance from "../performancesandorders/performances/CreatePerformances";
import PerformancePage from "../performancesandorders/performances/PerformancePage";

class AllcategoriesBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpallctg" component={Help} />      
          <Route path="/visitsallctg" component={Visits} />              
          <Route path="/loginscreenallctg" component={Loginscreen} />   
          <Route path="/contactallctg" component={Contact} />     
          <Route path="/registeruserallctg" component={RegisterUser} />
          <Route path="/performancesallctg" component={PerformancePage} /> 
          <Route path="/generateperformanceallctg" component={CreatePerformance} /> 
          <Route path="/changepasswordallctg" component={ChangePassword} /> 
          <Route path="/loginallctg" component={Login} /> 
          <Route path="/logoutallctg" component={Logout} /> 
          <Route path="/servicesuballctg" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedallctg" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsallctg" component={UploadAssets} />
          <Route path="/uploadpicturesallctg" component={UploadPictures} />
          <Route path="/uploadvideosallctg" component={UploadVideo} />          
          <Route path="/detailsdevallcategories" component={AssetDetailsPageDev} />
          <Route path="/detailsallcategories" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationallctg" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedallctg" component={ThankyouPasswordChanged} />
          <Route path="/buyticketallctg" component={BuyTicket} />
          <Route path="/collectaninviteasvipallctg" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvigoldenrodlctg" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventallctg" component={ConfirmParticipationToEvent} />         
          <Route path="/manageeventallctg" component={ManageEvent} />
          <Route path="/displaymydashboardallctg" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsallctg" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(AllcategoriesBody);
