

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../../services/urlService";
import GetEnvironment  from"../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
import LoadMoreAssets from "./pages/landingPages/LoadMoreAssetsRoot";
import SetAppName from "./partiesServices/setAppName";
import NavBarParties from "./responsive/Navbar";
function Parties() { 
  const [env,  setEnv] = useState('')
 

 
  // useEffect(() => {
  //   SetAppName();
  // },)

//   useEffect(() => {
//     // Prompt confirmation when reload page is triggered
//     window.onbeforeunload = () => { return "" };
        
//     // Unmount the window.onbeforeunload event
//     return () => { window.onbeforeunload = null };
// }, []);


  // useEffect(() => {
  //   LocalStorageService("set", "servicecategory", "party");
  // },)


  return (  
    <div className="container signupforevents-party-lp-content"> 
    
       <NavBarParties/>
       <LoadMoreAssets/> 
       <p></p>   
       
        </div>
        )
}
export default Parties


