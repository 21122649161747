import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
// import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionLeisure";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import CreatePerformance from "../performancesandorders/performances/CreatePerformances";
import PerformancePage from "../performancesandorders/performances/PerformancePage";

class LeisureBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpleis" component={Help} />      
          <Route path="/visitsleis" component={Visits} />              
          <Route path="/loginscreenleis" component={Loginscreen} />   
          <Route path="/contactleis" component={Contact} />     
          <Route path="/registeruserleis" component={RegisterUser} />
          <Route path="/performancesleis" component={PerformancePage} /> 
          <Route path="/generateperformanceleis" component={CreatePerformance} /> 
          <Route path="/changepasswordleis" component={ChangePassword} /> 
          <Route path="/loginleis" component={Login} /> 
          <Route path="/logoutleis" component={Logout} /> 
          <Route path="/servicesubleis" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedleis" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsleis" component={UploadAssets} />
          <Route path="/uploadpicturesleis" component={UploadPictures} />
          <Route path="/uploadvideosleis" component={UploadVideo} />          
          <Route path="/detailsdevleisure" component={AssetDetailsPageDev} />
          <Route path="/detailsleisure" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationleis" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedleis" component={ThankyouPasswordChanged} />
          <Route path="/buyticketleis" component={BuyTicket} />
          <Route path="/collectaninviteasvipleis" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinviteleis" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventleis" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinviteleis" component={CreateOrdinaryInvite} />
          <Route path="/createvipinviteleis" component={CreateVIPInvite} />
          <Route path="/createeventticketleis" component={CreateEventTicket} />
          <Route path="/displayinviteleis" component={DisplayInvite} />
          <Route path="/displayperformanceleis" component={DisplayPerformance} />
          <Route path="/displayeventticketleis" component={DisplayEventTicket} />
          <Route path="/manageeventleis" component={ManageEvent} />
          <Route path="/displaymydashboardleis" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsleis" component={SeeParticipants} />         
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(LeisureBody);
