import React, { Suspense,  useState, useEffect  } from "react";
import i18n from "./services/i18n";
import { withTranslation } from "react-i18next";
import Main from "./mainbody";
import Footer from "./footer/Footer";
import "./mainApp.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Stylesheets/StyleSheet.css";
import NavBar from "./responsive/Navbar";
import NavBarCustomevents from "./categories/customevents/responsive/Navbar";
import NavBarEntertainments from "./categories/entertainments/responsive/Navbar";
import NavBarLeisure from "./categories/leisure/responsive/Navbar";
import NavBarParties from "./categories/parties/responsive/Navbar";
import NavBarSport from "./categories/sport/responsive/Navbar";
import NavBarTrainings from "./categories/trainings/responsive/Navbar";
import NavBarAllCategories from "./categories/allcategories/responsive/Navbar";
import LocalStorageService from "./services/localStorageService";
import CookieConsent from "react-cookie-consent";
//import NavBarCeremonies from "./categories/ceremonies/responsive/Navbar";
//import NavBarConferences from "./categories/conferences/responsive/Navbar";
//import NavBarCreative from "./categories/creative/responsive/Navbar";
//import NavBarFairs from "./categories/fairs/responsive/Navbar";
//import NavBarFestivals from "./categories/festivals/responsive/Navbar";
//import NavBarMusic from "./categories/music/responsive/Navbar";
//import NavBarSpectacles from "./categories/spectacles/responsive/Navbar";
//import NavBarTourism from "./categories/tourism/responsive/Navbar";



function App({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [hasServiceCategory, setHasServiceCategory] = useState(false);

const [time, setTime] = useState(Date.now());

useEffect(() => {
  const interval = setInterval(() => setTime(Date.now()), 300);
  return () => {
    clearInterval(interval);
  };
}, []);

  var servicecategory = LocalStorageService("get","servicecategory") ;
  return (
    <div>
    <div >
      <div className = "header-akwaba">
      <NavBar /> 
      </div>
      <div className = "">
      <Suspense fallback={<div>Loading</div>}>
      <Main />
      </Suspense>
      <CookieConsent
  location="bottom"
  buttonText="I Understand"
  cookieName="signupforeventscookieconsent"
  style={{ background: "#967bb6 " }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}>
  
  <span style={{ fontSize: "30px" }}>This website uses cookies to enhance the user experience.{" "}</span>
</CookieConsent>
      </div>
      
    </div>
    <Footer />
    </div>
  );
}

export default withTranslation()(App);
