import React, { useState, useEffect } from "react";
import axios from "axios";
import {NavLink, HashRouter } from "react-router-dom";
import GetUrl from "../../services/urlService";
import { slice } from 'lodash';
import { Row, Col } from "react-bootstrap";


const Comments = (props) => {   
    const [post, setPost] = useState([]);
    const [Id, setId]= useState();
    const [Created_At, setCreated_At]= useState();
    const [FirstName, setFirstName]= useState(); 
    const [LastName, setLastName]= useState(); 
    const [Comment,  setComment]= useState();
    const [ProductId, setProductId]= useState();
    const [Email, setEmail]= useState();
    const [Telephone, setTelephone]= useState();  
    const [propertyid, setPropertyid] = useState(localStorage.getItem('Id'));
    const [index, setIndex] = useState(1) ;
    const initialPosts = slice(post, 0, index);
    const [isCompleted, setIsCompleted] = useState(false);

    // useEffect(() => { 
    //     setTimeout(() =>  getData(), 100);
    //   }, []);


      const loadMore = () => {
        setIndex(index + 1)
        console.log(index)
        if(index >= post.length){
          setIsCompleted(true)      
        } else {
          setIsCompleted(false)
        }
      }

      useEffect(() => {
        getData()
      }, [])

      
    function getApiPath () {     
        return GetUrl("getCommentsById");   
      }

    //   setPropertyid(window.location.href.split('?')[1])
    //  const prodId = window.location.href.split('?')[1]



    const getData = () => {       
      const url =  getApiPath();
     // const prodId = window.location.href.split('?')[1];
      axios.post(url, {         
        prodId: window.location.href.split('?')[1]
         })
          .then(res => {
             if(res.data !=="no data") 
             {
              console.log(res.data)
              setPost(res.data);
              console.log(post)
            }
           
          })
        .catch((e) => console.log(e)) 
    }

    return (
        <div>      
         <Row> 
    <Col md={{ span: 6, offset: 3 }}>
    {initialPosts.map((item) => {

        return (
          <div>                   
          <div
            className="lpParty"
            key={item?.ProductId}> 
                                       
             
              <div className ="" ><b>Date: </b>{item?.Created_At}.</div>
              <div className ="" ><b>Name:</b> {item?.FirstName + " " + item?.LastName} </div>
              <div className ="" ><b>Comment:</b> <br/>{item?.Comment}.</div>
            </div> 

            <div className="" Style="padding:15px"></div>   
          </div> 
           
                   
        )
      })}


     </Col>
          </Row>

          <div className="d-grid mt-3 mb-5">
        {isCompleted ? (
          <button
            onClick={loadMore}
            type="button"
            className="btn btn-danger disabled"
          >
            End.
          </button>
        ) : (
          <button onClick={loadMore} type="button" className="btn btn-danger">
           More...
          </button>
        )}
      </div>
          </div>
          )
    }
    export default Comments