import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import GetUrl from "../../services/urlService";
import LocalStorageService from '../../services/localStorageService';
import { NavLink, Link, HashRouter } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
//import SetAppName from '../partiesServices/setAppName';
import Card from "react-bootstrap/Card";
import provisionalticket from "../../images/provisonalticket.png";
import finalticket from "../../images/finalticketparties.png";
import MakePayment from "../../payments/MakePaymentRoot";

const TicketPage = () => {

  const [contactEmail, setContactEmail] = useState('');
  const [ticket, setTicket] = useState('');
  //const [invoices2, setInvoices2] = useState('');
  const [results, setResult] = useState([]);
  const [paidStatus, setPaidStatus] = useState([]);  
  const [item1Name, setItem1Name] = useState([]);
  const [item1Price, setItem1Price] = useState([]);
  const [item1Qty, setItem1Qty] = useState([]);
  const [item2Name, setItem2Name] = useState([]);
  const [item2Price, setItem2Price] = useState([]);
  const [item2Qty, setItem2Qty] = useState([]);
  const [item3Name, setItem3Name] = useState([]);
  const [item3Price, setItem3Price] = useState([]);
  const [item3Qty, setItem3Qty] = useState([]);
  const [item4Name, setItem4Name] = useState([]);
  const [item4Price, setItem4Price] = useState([]);
  const [item4Qty, setItem4Qty] = useState([]);
  const [item5Name, setItem5Name] = useState([]);
  const [item5Price, setItem5Price] = useState([]);
  const [item5Qty, setItem5Qty] = useState([]);
  const [item6Name, setItem6Name] = useState([]);
  const [item6Price, setItem6Price] = useState([]);
  const [item6Qty, setItem6Qty] = useState([]);

  const getData = async () => {
    const API_PATH = getApiPath(); 
    
    const body = {
      invoiceNumber: getId()
    };

    const result = await 
    axios({
      method: "post",     
      url: `${API_PATH}`,
      data: body,
    })
    setResult(result.data);
    setPaidStatus(result?.data[0].Status)

    console.log('result:', results);
  };

  useEffect(() => {
    getData();
    }, [])


useEffect (() =>{
  setContactEmail(LocalStorageService("get", "userEmail"))
}, );


 function getToken (){
  return LocalStorageService("get", "token"); 
}; 



function  getId (){  
  return LocalStorageService("get", "TicketNumber"); 
}; 

const  getApiPath = () =>  {   
  return GetUrl("retrieveTicketById");
};


return (
  <div  className="content-akwaba">
        {results.map((item) => {          
        
          return (
           
            <div className="ticketStyle">

           <p></p>
       
            <Row >           
             <Col md={{ span: 6, offset: 2 }}>  
            <div className ="invoices-container " >
            <ul Style="list-style-type: none">         
            <li Style="color:Black; justifyContent:center; position:flex"><h3><b>Provisional Ticket Id: {item.Id}</b></h3></li>
           <li><p Style="background:Red">Ticket is invalid at the moment. click the specified link below and  using your ticket Id or Ticket Number, validate your ticket by making payment.</p></li>
            </ul>
            </div>
            </Col>
            </Row > 

            <p></p> 
            <p></p>
            <Row >  
            <Col>
            <div>
            <ul Style="list-style-type: none">
            <li >Ticket Number: {item.RandomNumber}</li>
            <li >Valid from: {item.ValidFrom}</li> 
            <li >Valid to: {item.ValidTo}</li> 
           </ul>
           </div>
            <p></p>
            </Col>
            <Col md={{ span: 6, offset: 2 }}>  
            <ul Style="list-style-type: none"> 
           <div>        
            <li >Title: {item.title}</li>
            <li >First Name: {item.FirstName}</li>
             <li >Last Name: {item.LastName}</li>
             </div>
             </ul>
             </Col>
           </Row>
           <Row>
           <Col>
           <ul Style="list-style-type: none">           
          <p></p>
       
          <li >Event code: {item.Event}</li> 
          <li >{item.Performance1!=" "? "Performance 1: "+item.Performance1: ""}</li>
          <li >{item.Performance1!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance2!=" "? "Performance 2: "+item.Performance2: ""}</li>
          <li >{item.Performance2!=" "? "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance3!=" "?  "Performance 3: "+item.Performance3: ""}</li>
          <li >{item.Performance3!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance4!=" "?  "Performance 4: "+item.Performance4: ""}</li>
          <li >{item.Performance4!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance5!=" "? "Performance 5: "+item.Performance5: ""}</li>
          <li >{item.Performance5!=" "? "Seat Number: "+item.SeatNumber: ""}</li>
          <li >{item.Performance6!=" "? "Performance 6: "+item.Performance6: ""}</li>
          <li >{item.Performance6!=" "?  "Seat Number: "+item.SeatNumber: ""}</li>
          <li >Total: £{item.TotalPrice}</li>        
           <p></p>
           <p></p>
           </ul>
           </Col>

           <Col>
           <ul Style="list-style-type: none">         
           <li>*********************************************************</li>
          
          <li >Paid Status: {item.Status==1?'Valid':'Unpaid'}</li>
          <li >Date Paid: {item.DatePaid?item.DatePaid:'N/A'}</li>         
                   
          <li>*********************************************************</li>
         </ul>        
             </Col>
             </Row>   

                <Row>
             <Col>
            {paidStatus == "unpaid" &&(<div>
              <p></p>
              <span>  
              <NavLink to="/makepaymentroot"><span className =""><h1>Click me to make payment</h1></span></NavLink>            
                 </span>          
             </div>
            )}

           <p></p>
           <p style ="color: blue">Powered by Marveltech sponsored by Sign up for events</p>
           <p></p>
           <p></p>
             </Col>
           </Row>          
      </div>
          )
        })}
         
    </div>   
    )
  }

 
export default TicketPage;