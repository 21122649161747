import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import CONFIG from "../config.json";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../services/urlService";
import GetEnvironment  from "../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";


function LoadMoreAssetsLive(props) {
  const [post, setPost] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)
  const [index, setIndex] = useState(3) 
  const [search,  setSearch] = useState(false)
  const [isSearchValid,  setIsSearchValid] = useState(true)
  const [siteSearch,  setSiteSearch] = useState('')
  const [siteSearchIndex,  setSiteSearchIndex] = useState('')
  const [productIdentificationNumber,  setProductIdentificationNumber] = useState([])
  const [eventLocation,  setEventLocation] = useState('')
  const [eventCapacity,  setEventCapacity] = useState('')

  const initialPosts = slice(post, 0, index)
  
  // useEffect (() =>{
  //   SetAppName();
  // });

  function getApiPath () {     
    return GetUrl("retrieveAssetsMix");   
  }

  function getApiPathForSearch () {     
    return GetUrl("search");   
  }

  const getData = () => {       
    const url =  getApiPath();
        axios.get(url)
        .then(res => {
          if(res.data !=="no data") 
          {
            setPost(res.data);
          }
         
        })
      .catch((e) => console.log(e)) 
  }

  const loadMore = () => {
    setIndex(index + 1)
    console.log(index)
    if(index >= post.length){
      setIsCompleted(true)      
    } else {
      setIsCompleted(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  //const env = GetEnvironment();
  
  const  startSearch = (e) =>{   
    const url = getApiPathForSearch();
    if(siteSearch=="")
    {
      setIndex(0);
      setIsSearchValid(false);
      return
    }
    axios.post(url, {         
       siteSearch: siteSearch           
          })
          .then(res => {  
           setPost(res.data);
           setSearch(true); 
           setSiteSearch('');
       })
       .catch((e) => console.log(e))    
  }
  // const mainimage = `/${this.props.obj.PathMainImage}`;
  return (
    <div>    
      <p></p>
      {initialPosts.map((item) => {
        const mainimage = `/${item?.PathMainImage}`;
        const opt1image = `/${item?.PathFirstOptionalImage}`;
        const opt2image = `/${item?.PathSecondOptionalImage}`;
        const opt3image = `/${item?.PathThirdOptionalImage}`;
        const emptyText = ""; // falsy
        var videosrc =  item?.Videopath ?? "videos/thesun.earthrotating.mp4video.mp4"
        const video = `/${videosrc}`;
        const eventNote = item?.Sellernote;
        const eventDescription = item?.Description?? 'Party';
        const eventName = item?.EventName;
        const eventPrice = item?.Price;
        const dateAvailable =item?.InsertionDate?? new Date();
        const formattedDate = dateAvailable.slice(0, 10);//dateAvailable.split(' ')[0];
        const location = item?.Address;
        const contact = item?.SellerPhone? ' Your Party organiser phone number: ' + item?.SellerPhone :'';
        const productId = item?.productId;
        const productName = item?.productName;
        const serviceModel = item?.ServiceModel;
        const deliveryModel = item?.DeliveryModel;
        const hasMenue = item?.MenueStatus;
        const sellerEmail = item?.SellerEmail;
        const sellerPhone = item?.SellerPhone;
        const venue =item?.Venue;
        const typeOfEvent= item.TypeOfEvent;       

        LocalStorageService("set", "sellerPhoneNumber", item?.SellerPhone);
        LocalStorageService("set", "sellerEmail", item?.SellerEmail);
        LocalStorageService("set", "venue", item?.Venue);
        LocalStorageService("set","hasMenue", item?.HasMenue);
        LocalStorageService("remove","productId"); 
        LocalStorageService("set","mainimage", mainimage);
        LocalStorageService("set","opt1image", opt1image);
        LocalStorageService("set","opt2image", opt2image);
        LocalStorageService("set","opt3image", opt3image);
        LocalStorageService("set","video", video);
        LocalStorageService("set","assetNote", eventNote);
        LocalStorageService("set","assetDescription", eventDescription);
        LocalStorageService("set","assetName", eventName);
        LocalStorageService("set","assetPrice", eventPrice);
  
        LocalStorageService("set","prodName", productName);
        
        const serviceModelReworkedIntoCapacity = () =>{        
          switch(item.Size){
            case 'canMicroEvent':
              return 'Less than 50 people'
              case 'canMiniEvent':
                return '50 to 100 people'
              case 'canSmallEvent':
                return '100 to 200 people'
              case 'canMediumEvent':
                return '200 to 350 people'
              case 'canBigEvent':
                return '350 to 500 people'
              case 'canLargeEvent':
                return '500 and more people'
                case 'cannotEvent':
                  return 'No event commodities'                
          default:
          return 'None applicable';
          }
        }
      
        const deliveryModelReworkedIntoLocation = () =>{        
          switch(item.DeliveryModel){
          case 'AtHome':
          return 'Party at the organiser`s home'
          case 'InVenue':
            return 'In an outside venue'
            case 'Other':
              return 'Other'                   
          default:
          return 'None applicable';
          }
        
        } 
     
       

         console.log("windows location: ", window.location.href)

        return (
          <div>         
          <div
            className="lpParty"
            key={item?.productId}
          >                            
              <div className ="lpParty-Content" ><h3>{eventDescription}.</h3></div>
              <div className ="lpParty-Content" ><h3>Posted since {formattedDate}.</h3></div>
              <div className ="lpParty-Content" ><h3> Location:  {deliveryModelReworkedIntoLocation()}.</h3></div>
              <div className ="lpParty-Content" ><h4>Event capacity: {serviceModelReworkedIntoCapacity()}.</h4></div>
              <div className ="lpParty-Content" ><h4>Event venue: {venue}.</h4></div>
              <div className ="lpParty-Content" ><h4>Identification number: {item?.productId}.</h4></div>
              <div className ="lpParty-Content" >   
       
                <Link to={{
                  pathname: "/details"+typeOfEvent+"?"+productId, 
                    state: {
                    mainimage, opt1image, opt2image, opt3image,
                    eventNote, eventDescription, eventName, eventPrice,
                    video, productIdentificationNumber, productName, 
                    serviceModel, deliveryModel, hasMenue,
                    sellerEmail, sellerPhone, venue
                  },
                  productid:  `?${productId}`,
                  mainImage: `?${mainimage}`                  
            }}>
               <Row>    
               <Col md={{ span: 3, offset: 4 }}>
              <div className ="home-page-image"> 
              <img src={mainimage}  width="100%" height="100%" />                           
              </div>
              </Col>
              </Row>
               <div  className="lpParty-link-dlp"> <h4> {contact}. <br/>Click on this link for more details.</h4></div>
            </Link>
            </div>
            </div>           
            </div>          
        )
      })}

{isSearchValid && ( <div className="d-grid mt-3 mb-5">
        {isCompleted ? (
          <button
            onClick={loadMore}
            type="button"
            className="btn btn-danger disabled"
          >
            There is no more to load.
          </button>
        ) : (
          <button onClick={loadMore} type="button" className="btn btn-danger">
            More...
          </button>
        )}
      </div>
      )}
    </div>
  )
}
export default LoadMoreAssetsLive


