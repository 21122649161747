import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../services/urlService";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";

function LoadBookings() {
  const [post, setPost] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)
  const [index, setIndex] = useState(3) 
  const [search,  setSearch] = useState(false)
  const [isSearchValid,  setIsSearchValid] = useState(true)
  const [siteSearch,  setSiteSearch] = useState('')
  const [siteSearchIndex,  setSiteSearchIndex] = useState('')
  const [productIdentificationNumber,  setProductIdentificationNumber] = useState([])
  const [eventLocation,  setEventLocation] = useState('')
  const [eventCapacity,  setEventCapacity] = useState('')

  const initialPosts = slice(post, 0, index)
  
  function getApiPath () {     
    return GetUrl("retrieveBookings");   
  }

  const getData = () => {       
    const url =  getApiPath();
        axios.get(url)
        .then(res => {
          if(res.data !=="no data") 
          {
            setPost(res.data);
          }         
        })
      .catch((e) => console.log(e)) 
  }

  const loadMore = () => {
    setIndex(index + 1)
    console.log(index)
    if(index >= post.length){
      setIsCompleted(true)      
    } else {
      setIsCompleted(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])
  

  return (
    <div>
      
      <p></p>
      {initialPosts.map((item) => {
//console.log({item})
        //const env = item?.environmentType;
       
         
        const DateContacted = item?.DateBooked;
        const Title = item?.VIPCode;
        const Name = item?.UserFullName ;
        const Contact = item?.UserContact;
        const OrganiserAccount =item?.OrganiserAccount;   
        const Venue = item?.Venue;
        const Sponsorship = item?.Sponsorship;
        const Fees = item?.Fees;
        const UserId = item?.UserId;
        const DateBookingOpen = item?.DateBookingOpen;
        const EventRandCode = item?.EventRandCode;
        const UserStatus = item?.UserStatus;
        const Description = item?.Description;
        const Category = item?.Category;
        return (
          <div>         
          <div
            className="lpParty"
            key={item?.productId}
          >                            
              <div className ="lpParty-Content" ><h3>Date: {DateContacted}.</h3></div>
              <div className ="lpParty-Content" ><h3>Title {Title}.</h3></div>
              <div className ="lpParty-Content" ><h3> Name:  {Name}.</h3></div>
              <div className ="lpParty-Content" ><h4>Contact: {Contact}.</h4></div>
              <div className ="lpParty-Content" ><h4>OrganiserAccount: {OrganiserAccount}.</h4></div>            
              <div className ="lpParty-Content" > <h4>Venue: {Venue}.</h4></div>
               <div  className="lpParty-link-dlp"> <h4> Sponsorship {Sponsorship}.</h4></div>
               <div className ="lpParty-Content" ><h3>Fees: {Fees}.</h3></div>
              <div className ="lpParty-Content" ><h3>UserId {UserId}.</h3></div>
              <div className ="lpParty-Content" ><h3> DateBookingOpen:  {DateBookingOpen}.</h3></div>
              <div className ="lpParty-Content" ><h4>EventRandCode: {EventRandCode}.</h4></div>
              <div className ="lpParty-Content" ><h4>UserStatus: {UserStatus}.</h4></div>
              <div className ="lpParty-Content" ><h4>Description: {Description}.</h4></div>
              <div className ="lpParty-Content" > <h4>category: {Category}.</h4></div>
               </div>
            </div>          
                   
        )
      })}

{isSearchValid && ( <div className="d-grid mt-3 mb-5">
        {isCompleted ? (
          <button
            onClick={loadMore}
            type="button"
            className="btn btn-danger disabled"
          >
            no more to be loaded.
          </button>
        ) : (
          <button onClick={loadMore} type="button" className="btn btn-danger">
            Load more.
          </button>
        )}
      </div>
      )}
    </div>
  )
}
export default LoadBookings


