
//https://www.itsolutionstuff.com/post/password-and-confirm-password-validation-in-reactexample.html

import { useTranslation } from 'react-i18next';
import { React,useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import GetUrl from "../../../services/urlService";

const ChangePassword = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  //const [usertype, setUserType] = useState('');  
  const [messageSent, setMessageSent] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');  
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [passworderrormsg, setPassworderrormsg] = useState('');
  const [confirmpassworderrormsg, setConfirmPassworderrormsg] = useState('');
  const [pswandconfpswdiff, setConfPswandpswdiff] = useState('');
   
  const { t } = useTranslation();

  const handleSubmit = event => {
    console.log('handleSubmit ran');
    event.preventDefault(); // 👈️ prevent page refresh
   const body ={email,  password}; 

   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     if (validate()) {
    const API_PATH = GetUrl("resetPassword")
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) {         
                    
            setEmail('');       
            setPassword('');
            setConfirmPassword('');
            setPassworderrormsg('');
            setConfirmPassworderrormsg('');                
            setEmailerrormsg('');
            setMessageSent(true);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    } else { 
      setClickedButtonButNotPosted(true);
      setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
      console.log("Data was not sent");
      return;
    }
  }

 const  validate = ()=> {
    let isValid = true; 
  
    if (!email) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (!password) {
      isValid = false;
      setPassworderrormsg(<p>{t("pages.contact.text.passwordlinvalidmsg")}</p>);
    }

    if (!confirmpassword) {
      isValid = false;
      setConfirmPassworderrormsg(<p>{t("pages.contact.text.confirmpasswordlinvalidmsg")}</p>);
    }


    if (password!==confirmpassword) {
      isValid = false;
      setConfPswandpswdiff(<p>{t("pages.contact.text.pswandconfpswdiff")}</p>);
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }
     
    return isValid;
  };
  
  return (
    <div  className="content-akwaba">
      <form action="#">
        <div><p>{t("pages.contact.text.header2")}</p></div>
  
        <div className="form-group">
        <label>{t("pages.contact.text.email")}</label>
        <input
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder={t("pages.contact.text.emailph")}
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>
     
            <div className="form-group">
              <label>{t("pages.contact.text.password")}</label>
               <input
                 type="password"
                 name="password"
                 value={password}
                 onChange={event => setPassword(event.target.value)}
                 placeholder={t("pages.contact.text.passwordph")}
                 id="password"
              />
               <div className="text-danger">{passworderrormsg}</div>
             </div>

             <div className="form-group">
               <label>{t("pages.contact.text.confirmpassword")}</label>
               <input
                type="password"
                 name="confirmpassword"
                 value={confirmpassword}
                 onChange={event => setConfirmPassword(event.target.value)}
                 placeholder={t("pages.contact.text.confirmpasswordph")}
                 id="confirmpassword"
               />
               <div className="text-danger">{confirmpassworderrormsg}</div>              
               <div className="text-danger">{pswandconfpswdiff}</div>               
             </div> 
      
            <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {handleSubmit(e)}}
              defaultValue={t("pages.contact.text.submit")}
             />       
        <div>
               {messageSent && (
                <div>
                Thank your password has been changed. <br />
                You may login now <br /> 
                <div>                
                  <button><Link to="./logincuevt" relative="path">
                      Login
                      </Link>
                      </button>
                </div>
                  </div>
              )}
              
              {clickedButtonButNotPosted && (
                <div>
                  {clickedNotPostedMessage} <br />                  
                </div>
                 )}
         </div>

      </form>
    </div>
  );
};

export default ChangePassword;


