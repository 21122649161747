import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionTraining";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import PerformancePage from "../performancesandorders/performances/PerformancePage";
import CreatePerformances from "../performancesandorders/performances/CreatePerformances";

class TrainingBody
 extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helptraining" component={Help} />      
          <Route path="/visitstraining" component={Visits} />              
          <Route path="/loginscreentraining" component={Loginscreen} />   
          <Route path="/contacttraining" component={Contact} />     
          <Route path="/registerusertraining" component={RegisterUser} />
          <Route path="/performancestraining" component={PerformancePage} /> 
          <Route path="/generateperformancetraining" component={CreatePerformances} /> 
          <Route path="/changepasswordtraining" component={ChangePassword} /> 
          <Route path="/logintraining" component={Login} /> 
          <Route path="/logouttraining" component={Logout} /> 
          <Route path="/servicesubtraining" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedtraining" component={ThankYouUploaded} /> 
          <Route path="/uploadassetstraining" component={UploadAssets} />
          <Route path="/uploadpicturestraining" component={UploadPictures} />
          <Route path="/uploadvideostraining" component={UploadVideo} />          
          <Route path="/detailsdevtraining" component={AssetDetailsPageDev} />
          <Route path="/detailstraining" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationtraining" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedtraining" component={ThankyouPasswordChanged} />
          <Route path="/buytickettraining" component={BuyTicket} />
          <Route path="/collectaninviteasviptraining" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitetraining" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventtraining" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinvitetraining" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitetraining" component={CreateVIPInvite} />
          <Route path="/createeventtickettraining" component={CreateEventTicket} />
          <Route path="/displayinvitetraining" component={DisplayInvite} />
          <Route path="/displayperformancetraining" component={DisplayPerformance} />
          <Route path="/displayeventtickettraining" component={DisplayEventTicket} />
          <Route path="/manageeventtraining" component={ManageEvent} />
          <Route path="/displaymydashboardtraining" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantstraining" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(TrainingBody);
