import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import GetMessageDashboard from "./MessagingDashboard";
import SuperUserDashboard from "./SuperUserDashboard";
import FindUsers from "./FindUsers";

class AdminBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/messagedashboard" component={GetMessageDashboard} /> 
          <Route path="/superuserservice" component={SuperUserDashboard} /> 
          <Route path="/usersservice" component={FindUsers} /> 
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(AdminBody);
