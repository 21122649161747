import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionEntertainment";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "../booking/BuyATicket";
import CollectAnInviteAsVIP from "../booking/CollectAVIPInvite";
import CollectInvite from "../booking/CollectInvite";
import ConfirmParticipationToEvent from "../booking/CollectInvite";
import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";
import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import CreatePerformances from "../performancesandorders/performances/CreatePerformances";
import PerformancePage from "../performancesandorders/performances/PerformancePage";

class EntertainmentBody extends Component {
  render() {
    return (
        <HashRouter> 
         {/* <Route path="/makepaymentbtent" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbpent" component={MakePaymentBp} /> 
          <Route path="/makepaymentolent" component={MakePaymentOl} />  */}

          <Route path="/helpent" component={Help} />      
          <Route path="/visitsent" component={Visits} />              
          <Route path="/loginscreenent" component={Loginscreen} />   
          <Route path="/contactent" component={Contact} />     
          <Route path="/registeruserent" component={RegisterUser} />
          <Route path="/performancesent" component={PerformancePage} /> 
          <Route path="/generateperformanceent" component={CreatePerformances} /> 
          <Route path="/changepasswordent" component={ChangePassword} /> 
          <Route path="/loginent" component={Login} /> 
          <Route path="/logoutent" component={Logout} /> 
          <Route path="/servicesubent" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedent" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsent" component={UploadAssets} />
          <Route path="/uploadpicturesent" component={UploadPictures} />
          <Route path="/uploadvideosent" component={UploadVideo} />          
          <Route path="/detailsdeventertainment" component={AssetDetailsPageDev} />
          <Route path="/detailsentertainment" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationent" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedent" component={ThankyouPasswordChanged} />
          <Route path="/buyticketent" component={BuyTicket} />
          <Route path="/collectaninviteasvipent" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinviteent" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventent" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinviteent" component={CreateOrdinaryInvite} />
          <Route path="/createvipinviteent" component={CreateVIPInvite} />
          <Route path="/createeventticketent" component={CreateEventTicket} />
          <Route path="/displayinviteent" component={DisplayInvite} />
          <Route path="/displayperformanceent" component={DisplayPerformance} />
          <Route path="/displayeventticketent" component={DisplayEventTicket} />
          <Route path="/manageeventent" component={ManageEvent} />
          <Route path="/displaymydashboardent" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsent" component={SeeParticipants} />
    
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(EntertainmentBody);
