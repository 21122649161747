

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../../services/urlService";
import GetEnvironment  from "../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
// import LoadMoreAssets from "./loadPages/LoadMoreAssetsRoot";
function Fairs() { 
  const [env,  setEnv] = useState('')
 
  useEffect(() => {
    LocalStorageService("remove", "servicecategory");
  }, [])

  useEffect(() => {
    LocalStorageService("set", "servicecategory", "fair");
  }, [])



  return (
   
    <div className="container signupforevents-fair-lp-content">      

       {/* <LoadMoreAssets/>  */}
       <p></p>   
       
        </div>
        )
}
export default Fairs


