// import React, {Component,  useState, useEffect } from 'react';
// import { withTranslation } from "react-i18next";
// import { NavLink, Link, HashRouter } from "react-router-dom";
// import { Row, Col } from 'react-bootstrap';
// import LocalStorageService from './services/localStorageService';
// import DashboardCarousel from './DashboardCarousel';
// class HomeDashboard extends Component {
//   render() {
//     const { t } = this.props;
//     LocalStorageService("remove","servicecategory")
//     LocalStorageService("set","servicecategory", "home")
//     return (
//       <div>

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../services/urlService";
import GetEnvironment  from"../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";
import LoadMoreAssets from "./LoadMoreAssetsRoot";
import NavBarsocialMedias from "../responsive/Navbar";

function HomeDashboardMix() { 
  const [env,  setEnv] = useState('')
   return (  
    <div className="container signupforevents-party-lp-content"> 
   
       <LoadMoreAssets/> 
       <p></p>   
       
        </div>
        )
}
export default HomeDashboardMix

